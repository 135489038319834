import { createReducer, on } from '@ngrx/store';

import * as actions from './profile.actions';
import { ProfileState } from './profile.types';

const initialState: ProfileState = {
  item: null,
  permissions: null,
  isLoading: false,
  isLoaded: false,
  isError: false,
};

export const reducer = createReducer(
  initialState,

  on(actions.profileFetchItemStart, state => ({
    ...state,
    isLoading: true,
  })),

  on(actions.profileFetchItemSuccess, (state, { payload }) => {
    const [item, permissions] = payload;

    return {
      ...state,
      item,
      permissions,
      isLoading: false,
      isLoaded: true,
      isError: false,
    };
  }),

  on(actions.profileFetchItemFail, state => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    isError: true,
  })),

  on(actions.profileUpdateItemStart, state => ({
    ...state,
    isLoading: true,
  })),

  on(actions.profileUpdateItemSuccess, (state, { payload }) => ({
    ...state,
    item: payload,
    isLoading: false,
    isError: false,
  })),

  on(actions.profileUpdateItemFail, state => ({
    ...state,
    isLoading: false,
    isError: true,
  })),

  on(actions.profileResetItem, () => ({
    ...initialState,
  })),
);
