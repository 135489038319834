import { createAction, props } from '@ngrx/store';

import { NotificationItemResponse, NotificationsUnreadCountResponse } from './notifications.types';

export const notificationsFetchCurrentLimit = createAction('NOTIFICATIONS/FETCH_CURRENT_LIMIT');
export const notificationsFetchMore = createAction('NOTIFICATIONS/FETCH_MORE');
export const notificationsUpdateLoadCount = createAction(
  'NOTIFICATIONS/UPDATE_LOAD_COUNT',
  props<{ loadCount: number }>(),
);
export const notificationsFetchListStart = createAction(
  'NOTIFICATIONS/FETCH_LIST_START',
  props<{ payload: { pageIndex: number; pageSize: number } }>(),
);
export const notificationsFetchListSuccess = createAction('NOTIFICATIONS/FETCH_LIST_SUCCESS', props<{ payload }>());
export const notificationsFetchListFail = createAction('NOTIFICATIONS/FETCH_LIST_FAIL');

export const notificationsFetchUnreadCountStart = createAction('NOTIFICATIONS/FETCH_UNREAD_COUNT_START');
export const notificationsFetchUnreadCountSuccess = createAction(
  'NOTIFICATIONS/FETCH_UNREAD_COUNT_SUCCESS',
  props<{ payload: NotificationsUnreadCountResponse }>(),
);
export const notificationsFetchUnreadCountFail = createAction('NOTIFICATIONS/FETCH_UNREAD_COUNT_FAIL');
export const notificationsUpdateItemStart = createAction(
  'NOTIFICATIONS/UPDATE_ITEM_START',
  props<{ payload: { notificationId: number } }>(),
);
export const notificationsUpdateItemSuccess = createAction(
  'NOTIFICATIONS/UPDATE_ITEM_SUCCESS',
  props<{ payload: NotificationItemResponse }>(),
);
export const notificationsUpdateItemFail = createAction('NOTIFICATIONS/UPDATE_ITEM_FAIL');

export const notificationsMarkAllAsReadStart = createAction('NOTIFICATIONS/MARK_ALL_AS_READ_START');
export const notificationsMarkAllAsReadSuccess = createAction('NOTIFICATIONS/MARK_ALL_AS_READ_SUCCESS');
export const notificationsMarkAllAsReadFail = createAction('NOTIFICATIONS/MARK_ALL_AS_READ_FAIL');

export const notificationsResetList = createAction('NOTIFICATIONS/RESET_LIST');

export const notificationsResetUnreadCount = createAction('NOTIFICATIONS/RESET_UNREAD_COUNT');
