import dayjs from 'dayjs';
import 'dayjs/locale/en-nz';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

import { truncateNumberWithPrecision } from '../number.utility';

/** The formatted split between `date` and `time`. */
export interface DateAndTime {
  /** Human readable `date` text. */
  date: string;
  /** Human readable `time` text. */
  time: string;
}

export const MEDIUM_DATE_DISPLAY = 'D MMM YYYY';
const hasImport = dayjs && dayjs.locale && dayjs.extend;

/**
 * @note ONLY set days in the context of a browser and not part of the SSR sequence.
 */
if (hasImport) {
  /**
   * Set the DayJS object to always have the context of our NZ local.
   * @see https://day.js.org/docs/en/i18n/changing-locale
   */
  dayjs.locale('en-nz');

  /**
   * Enrich DayJS to parse dates from multiple formats like "YYYY-MM-DD" or
   * "DD-MM-YYYY" or "MM-DD-YYYY".
   * @see https://day.js.org/docs/en/plugin/custom-parse-format
   */
  dayjs.extend(customParseFormat);
  dayjs.extend(relativeTime);
  dayjs.extend(duration);
}

/**
 * Converts dotnet timespan to humanized string e.g. (d.)HH:mm:ss(.ffffff)
 */
export const formatDotNetTimeSpanToHuman = (dateString?: string): string => {
  if (dateString === undefined) {
    return '';
  }
  const groups = dateString.match(/(?:(\d{1,2})\.)?(\d{2}):(\d{2}):(\d{2})/);
  return dayjs
    .duration({
      days: parseInt(groups[1]),
      hours: parseInt(groups[2]),
      minutes: parseInt(groups[3]),
      seconds: parseInt(groups[4]),
    })
    .humanize();
};

/**
 * Converts dotnet timespan to minutes, hours or days
 */
export const formatDotNetTimeSpanToMinutesHoursDays = (dateString?: string): string => {
  if (dateString === undefined) {
    return '';
  }
  // Convert timespan to minutes
  const groups = dateString.match(/(?:(\d{1,2})\.)?(\d{2}):(\d{2}):(\d{2})/);
  const timeSpanInMinutes = dayjs
    .duration({
      days: groups[1] ? parseInt(groups[1]) : 0,
      hours: parseInt(groups[2]),
      minutes: parseInt(groups[3]),
      seconds: parseInt(groups[4]),
    })
    .asMinutes();
  // format to human string
  if (timeSpanInMinutes < 1) {
    return 'a few seconds';
  } else if (timeSpanInMinutes < 60) {
    const minutesRounded = Math.round(timeSpanInMinutes);
    // less than an hours display as rounded minutes
    if (minutesRounded == 1) {
      return `${minutesRounded} minute`;
    } else {
      return `${minutesRounded} minutes`;
    }
  } else if (timeSpanInMinutes < 2880) {
    // less than 2 days display as rounded hours
    const hoursRounded = Math.round(dayjs.duration({ minutes: timeSpanInMinutes }).asHours());
    if (hoursRounded == 1) {
      return `${hoursRounded} hour`;
    } else {
      return `${hoursRounded} hours`;
    }
  } else {
    // otherwise display as days with 1 decimal place
    const days = truncateNumberWithPrecision(dayjs.duration({ minutes: timeSpanInMinutes }).asDays(), 1);
    return `${days} days`;
  }
};

/**
 * Formats date to readable summary e.g 2019-11-11T20:10:37.720Z -> 12 Nov 2019
 */
export const formatDateToSummary = (dateString?: string): string => {
  const dateObj = dayjs(dateString);
  const isValid = !!dateString && dateObj.isValid();

  return isValid ? dateObj.format(MEDIUM_DATE_DISPLAY) : '';
};

/**
 * Once cool thing to note is that because we are testing our systems from a users
 * perspective and not against implementation details - all of our existing test
 * continued to pass even though their data was being rendered by a completely
 * new system.
 *
 * Takes a server-side date string and formats it into two parts:
 * + Generic date.
 * + Granular time.
 *
 * These parts are formatted for user readability.
 *
 * Multiple parts means that when displayed in a table the elements can collapse
 * on themselves and still retain readability.
 *
 * @example
 * formatDateAndTimeUtility("Tue May 07 2019 13:26:43 GMT+1200 (New Zealand Standard Time)");
 * // {
 * //   date: "07/05/2019",
 * //   time: "1:26:43 PM"
 * // }
 *
 * @example
 * <td>
 *   <span class="u-no-wrap">{{ item.login.date }}</span>&nbsp;
 *   <span class="u-no-wrap">{{ item.login.time }}</span>
 * </td>
 *
 * + Full Width cell:
 *   ----------------
 *   07/05/2019 1:26:43 PM
 *
 * + Collapsed cell:
 *   ---------------
 *   07/05/2019
 *   1:26:43 PM
 */
export const formatDateAndTimeUtility = (dateString?: string): DateAndTime => {
  const dateObj = dayjs(dateString);
  const isValid = !!dateString && dateObj.isValid();

  return {
    date: isValid ? dateObj.format(MEDIUM_DATE_DISPLAY) : '',
    time: isValid ? dateObj.format('h:mm:ss A') : '',
  };
};

/**
 *  the saved date will have format yyyy-mm-ddTHH:MM:SS
 * we need to convert to dd/mm/yyyy to DoB forms, and
 * yyyy-mm-dd to the normal date component
 */
export const formatBackendDateToClientInput = (dateType: string, dateString?: string): string => {
  const dateObj = dayjs(dateString);
  const isValid = !!dateString && dateObj.isValid();

  if (isValid && dateType === 'date') {
    return dateObj.format('YYYY-MM-DD');
  }

  if (isValid && dateType === 'dob') {
    return dateObj.format('DD/MM/YYYY');
  }

  return '';
};

// the saved DoB will have format yyyy-mm-ddTHH:MM:SS
// we need to convert from dd/mm/yyyy to yyyy-mm-dd
// export const convertDobIntoRequestData = (dob: string): { dateOfBirthNzt: string } => {
//   const bday = dayjs(dob, 'DD/MM/YYYY').locale('en-nz').format('YYYY-MM-DD');
//   return { dateOfBirthNzt: bday };
// };

export const formatDateRightNowAsISO = (): string => dayjs().toISOString();

/**
 * Takes a date (in the format of "DD-MM-YYYY") and returns a "to" and "from"
 * date encapsulating a financial year period that the supplied date falls into.
 *
 * @example formatFinancialYearDuration("01-01-2018");
 * // {
 * //   from: "1 April 2017",
 * //   to: "31 March 2018"
 * // }
 */
export const formatFinancialYearDuration = (
  dateString: string,
): {
  from: string;
  to: string;
} => {
  const queryStringFormat = 'DD-MM-YYYY';
  const currentDateObj = dayjs(dateString, queryStringFormat);
  const currentYear = currentDateObj.year();
  const newFinancialYear = dayjs(`01-04-${currentYear}`, queryStringFormat);
  const didEofyStartLastYear = currentDateObj.isBefore(newFinancialYear);
  const financialYearStart = didEofyStartLastYear ? currentYear - 1 : currentYear;
  const fromObj = dayjs(`01-04-${financialYearStart}`, queryStringFormat);
  const toObj = fromObj.add(1, 'year').subtract(1, 'day');
  const format = (dateObj: dayjs.Dayjs) => dateObj.format(MEDIUM_DATE_DISPLAY);

  return {
    from: format(fromObj),
    to: format(toObj),
  };
};

/**
 * Takes count of month and humanizes it to years and months
 *
 * @example formatFinancialYearDuration(54);
 * // 4 years, 6 months
 */
export const formatMonthstoYearsMonths = (monthCount: number): string => {
  let start = dayjs();
  const end = dayjs().add(monthCount, 'month');
  // calculate years
  const years = end.diff(start, 'year');
  const yearSuffix = years > 1 ? 'years' : 'year';
  // remove years from start so that months can calculate the difference
  start = start.add(years, 'year');
  const months = end.diff(start, 'month');
  const monthSuffix = months > 1 ? 'months' : 'month';
  // default formattedDuration
  let formattedDuration = '-';
  if (years > 0) {
    formattedDuration = `${years} ${yearSuffix}`;
    if (months > 0) {
      formattedDuration = `${formattedDuration}, ${months} ${monthSuffix}`;
    }
  } else if (months > 0) {
    formattedDuration = `${months} ${monthSuffix}`;
  }
  return formattedDuration;
};

/**
 * Takes date string and returns a humanized string starting with the
 * largest option followed by the next largest e.g "years months", "days hours"
 *
 * @example formatFinancialYearDuration('2022-03-01T00:00:00');
 * // 2 years 2 months
 */
export const formatTimeUntil = (endDateString: string): string => {
  // default formattedDuration
  let formattedDuration = '-';
  // set start and end date
  const end = dayjs(endDateString);
  if (end.isValid()) {
    let start = dayjs();
    // calculate years
    const years = end.diff(start, 'year');
    const yearSuffix = years > 1 ? 'years' : 'year';
    // remove years from start so that months can calculate the difference
    start = start.add(years, 'year');
    const months = end.diff(start, 'month');
    const monthSuffix = months > 1 ? 'months' : 'month';
    if (months >= 3) {
      // only remove months from the start days if its over 3 months as the is the threshold if its below this we use days
      start = start.add(months, 'month');
    }
    // continue other time breakdowns in the same way
    const days = end.diff(start, 'day');
    const daySuffix = days > 1 ? 'days' : 'day';
    start = start.add(days, 'day');
    const hours = end.diff(start, 'hour');
    const hourSuffix = hours > 1 ? 'hours' : 'hour';
    start = start.add(hours, 'hour');
    const minutes = end.diff(start, 'minute');
    const minuteSuffix = minutes > 1 ? 'minutes' : 'minute';
    // Build strings based on the 2 biggest time breakdowns possible
    if (years > 0) {
      formattedDuration = `${years} ${yearSuffix}`;
      if (months > 0) {
        formattedDuration = `${formattedDuration} ${months} ${monthSuffix}`;
      }
    } else if (months >= 3) {
      formattedDuration = `${months} ${monthSuffix}`;
      if (days > 0) {
        formattedDuration = `${formattedDuration} ${days} ${daySuffix}`;
      }
    } else if (days > 0) {
      formattedDuration = `${days} ${daySuffix}`;
      if (hours > 0) {
        formattedDuration = `${formattedDuration} ${hours} ${hourSuffix}`;
      }
    } else if (hours > 0) {
      formattedDuration = `${hours} ${hourSuffix}`;
      if (minutes > 0) {
        formattedDuration = `${formattedDuration} ${minutes} ${minuteSuffix}`;
      }
    } else if (minutes > 0) {
      formattedDuration = `${minutes} ${minuteSuffix}`;
    }
  }
  return formattedDuration;
};

export const calculateStartOfCurrentFinacialYear = (dateFormat: string): string => {
  if (dayjs().get('month') >= 3) {
    // If current date is after April this year get the start or april this year
    return dayjs().month(3).startOf('month').format(dateFormat);
  }
  // If current date is before April this year get the start or april the previous year
  return dayjs().subtract(1, 'year').month(3).startOf('month').format(dateFormat);
};

export const dayMonthYearIntoDate = ({
  day,
  month,
  year,
}: {
  day: string;
  month: string;
  year: string;
}): dayjs.Dayjs | undefined => {
  if (day == undefined || month === undefined || year === undefined) return undefined;

  const dob = new Date(Number(year), Number(month) - 1, Number(day));
  return dayjs(dob);
};

// the saved DoB will have format yyyy-mm-ddTHH:MM:SS
// we need to convert from days months years to yyyy-mm-dd
export const convertDobIntoRequestData = ({
  day,
  month,
  year,
}: {
  day: string;
  month: string;
  year: string;
}): string => {
  return dayMonthYearIntoDate({
    day,
    month,
    year,
  })
    ?.locale('en-nz')
    ?.format('YYYY-MM-DD');
};

export const formatDateToDOBInput = (
  dateString: string,
): {
  day: string;
  month: string;
  year: string;
} => {
  const dateObj = dayjs(dateString);
  const isValid = !!dateString && dateObj.isValid();

  if (isValid) {
    return {
      day: dateObj.date().toString(),
      month: (dateObj.month() + 1).toString(),
      year: dateObj.year().toString(),
    };
  }

  return {
    day: '',
    month: '',
    year: '',
  };
};
