import { Intercom } from '@sencrop/capacitor-intercom';
import rg4js from 'raygun4js';

import { environment } from '@environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationActionPerformedEvent } from '@capacitor-firebase/messaging';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { Observable, catchError, map, mergeMap, of, switchMap, take, tap } from 'rxjs';

import { CREATE_ENTITY_FLOW_ROUTE_PREFIX } from '@views/investor/investor.helpers';
import { convertRegisterTemplateIntoRequestData } from '@views/login/register/register.helpers';
import { NotificationsComponent } from '@views/notifications/notifications.component';
import { returnNotificationRoute } from '@views/notifications/notifications.helper';
import { NotificationEventData } from '@views/notifications/notifications.types';
import { UserMfaResponse } from '@views/security/security.types';

import { FullScreenDialog } from '@components/full-screen-dialog/full-screen-dialog.service';
import { SnackBarService } from '@components/snackbar/snackbar.service';
import {
  VersionUpdateDialogComponent,
  VersionUpdateDialogData,
} from '@components/version-update/version-update-dialog.component';

import { AnalyticsService } from '@core/utilities/analytics.service';
import { BrazeEventEnum, BrazeService } from '@core/utilities/braze.service';
import { LoggerService } from '@core/utilities/logger/logger.service';
import { SpecialErrorCodes } from '@core/utilities/networkLayer/interceptor.helper';
import { parseQueryParams } from '@core/utilities/url/query-params';

import packageInfo from '../../../../../package.json';
import { IntroStepEnum, NewUserStepDialogComponent } from '../new-user-steps/new-user-step-dialog.component';
import { IUserVerifyV2 } from '../user/user.types';
import * as actions from './auth.actions';
import {
  AuthChangePasswordRequest,
  AuthConfirmEmailRequest,
  AuthCreateUserSessionRequest,
  AuthCreateUserSessionResponse,
  AuthDeleteUserAccountRequest,
  AuthDeleteUserSessionRequest,
  AuthDeviceCheckinResponse,
  AuthForgotPasswordRequest,
  AuthHasPasswordRequest,
  AuthHasPasswordResponse,
  AuthRefreshTokenRequest,
  AuthRegisterUserItemTemplate,
  AuthRegisterUserRequest,
  AuthRegisterUserResponse,
  AuthResetPasswordRequest,
  AuthSetInitialPasswordRequest,
  AuthSetInitialPasswordResponse,
  AuthShortTermRefreshResponse,
  AuthUpdateShortTermTokenResponse,
  AuthUserResponse,
  CreateGenericRequest,
  PreferenceStorage,
  PushServerIdEnum,
  registerDeviceRequest,
} from './auth.types';
import { NativeAuthService } from './native-auth.service';
import { StorageService } from './storage.service';

/**
 * The Authentication service that abstracts as much of the "auth" sequence into
 * a single set of API hooks to use through out the application.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /**
   * The URL that we redirect the user BACK to after authenticating. This URL is
   * populated from the `AuthGuard` when a user attempts to access gated content
   * BEFORE they have authenticated. We store the reference URL to where they
   * wanted to go so that we can re-direct them AFTER they have logged in.
   */
  redirectUrl?: URL;
  newUserIntroDialogOpen = false;

  constructor(
    private http: HttpClient,
    private store: Store,
    private actions$: Actions,
    private router: Router,
    private snackBarService: SnackBarService,
    private loggerService: LoggerService,
    private brazeService: BrazeService,
    private analyticsService: AnalyticsService,
    private nativeAuthService: NativeAuthService,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private fullScreenDialog: FullScreenDialog,
    private ngZone: NgZone,
  ) {}

  /**
   * Checks if the user is considered authenticated to access Squirrels gated
   * content.
   *
   * We do this be checking if the user has a "Long Term" token in local storage.
   *
   * @note we are also checking the "userId". This is a late addition to the payload
   * and is critical for a session as it is the only reference that binds the user
   * to this application instance (for API calls).
   *
   * Users will still need a relevant "Short Term" token to access any gated data
   * (which is produced via the "Long Term" token).
   */
  checkIsLoggedIn = (): boolean => {
    return this.storageService.hasLongToken() && this.storageService.hasUserId();
  };

  /**
   * Hacky *TEMPORARY...* work around for customer-form pages to not be nested under the top level account components from app.component e.g sidenav
   *
   * Stops customer-form pages from having toolbar links to navigate away.
   */
  isCustomerFormRoute = (): boolean => {
    const isChildUrlSegment = this.activatedRoute.firstChild?.snapshot?.url?.length;
    if (!isChildUrlSegment) return false;

    return this.activatedRoute.firstChild.snapshot.url[0].path === 'customer-forms';
  };

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // Create New Short Term Token.  * * * * * * * * * * * * * * * * * * * * * * *
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

  /**
   * Creates a "Short Term" token to use in "generic" API requests. The "Short
   * Term" token has a TTL of 5 minutes. In that regard...
   *
   * + If there is a token sitting in local storage then we immediately return
   *   that to populate the API request.
   *
   * + If however, there is NO token (it was deleted because its TTL expired)
   *   then we need to hit the server and generate another one (and store it
   *   locally for future use).
   *
   *  @todo Test fail states when getting token...
   */
  createShortTermToken = (): Observable<AuthShortTermRefreshResponse> =>
    this.storageService.hasShortToken()
      ? /**
         * Fudge the response to "simulate" getting a token from the server (but
         * we just plucked it from local storage)..
         */
        of({ shortTermToken: this.storageService.getShortToken() })
      : /**
         * Make a legitimate request to the server to get a brand new "Short Term"
         * token.
         */
        this.http
          .post(
            `${environment.endpointApiTwo}/v2/login/refresh`,
            {
              longTermToken: this.storageService.getLongToken(),
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.storageService.getLongToken()}`,
              },
            },
          )
          .pipe(
            tap(({ shortTermToken }: AuthUpdateShortTermTokenResponse) => {
              this.storageService.setShortToken(shortTermToken);
            }),
          );

  /**
   * Returns "Short Term" token from the longterm token, that maybe saved externally like a keychain in the native apps
   */
  refreshShortTermToken = (payload: AuthRefreshTokenRequest): Observable<AuthShortTermRefreshResponse> => {
    const { longTermToken } = payload;
    return this.http.post<AuthShortTermRefreshResponse>(
      `${environment.endpointApiTwo}/v2/login/refresh`,
      {
        longTermToken,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${longTermToken}`,
        },
      },
    );
  };

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // Create User Session.  * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

  /** Dispatches the START sequence to authenticate a users session. */
  dispatchCreateUserSession = (payload: { username: string; password: string }): void =>
    this.store.dispatch(
      actions.authCreateUserSessionStart({
        payload,
      }),
    );

  /**
   * Log the user into the Squirrel application by authenticating a session with
   * the Back-end. This will deliver us the tokens that we need to route, and
   * request gated content.
   */
  createUserSession = (payload: AuthCreateUserSessionRequest): Observable<AuthCreateUserSessionResponse> => {
    return this.http.post<AuthCreateUserSessionResponse>(`${environment.endpointApiTwo}/v2/login`, payload);
  };

  /**
   * Handle the SUCCESS sequence after authentication has been validated and
   * returned from the server.
   */
  handleCreateUserSessionSuccess = (): void => {
    this.actions$.pipe(ofType(actions.authCreateUserSessionSuccess), take(1)).subscribe(({ payload }) => {
      /**
       * Cache the tokens in local storage so that we can leverage until they
       * expire (for performance as we do not need to make subsequent server
       * requests immediately).
       */
      const { userId, longTermToken, shortTermToken } = payload;
      this.setUser(`${userId}`);
      this.storageService.setLongToken(longTermToken);
      this.storageService.setShortToken(shortTermToken);

      // When a user session is created fetch user and userVerfify data
      this.store.dispatch(actions.authFetchUserSessionStart({ userId }));
      this.store.dispatch(actions.authFetchUserVerifyStart());

      this.actions$.pipe(ofType(actions.authFetchUserSessionSuccess), take(1)).subscribe(({ user }) => {
        Intercom.updateUser({
          email: user?.email,
          name: user?.fullName,
        });

        this.getPendingNotification(pendingNotification => {
          if (user?.isTemporaryPassword) {
            // Go to change password if user has a temporary password
            void this.router.navigate(['/settings/change-password']);
          } else if (this.redirectUrl) {
            /**
             * If the user was attempting to access gated content BEFORE being
             * asked to authenticate their session then re-route them back to
             * their original location.
             */

            const { pathname, search } = this.redirectUrl;
            // Reset redirect url as once the redirect is made its no longer needed
            this.resetRedirectUrl();

            void this.router.navigate([pathname], {
              queryParams: parseQueryParams(search),
            });
          } else if (pendingNotification) {
            // If a notification is pending send them there instead of the dashboard
            this.actionNotification('/');
          } else {
            // Default to dashboard for all othee situations
            void this.router.navigate(['/']);
          }
        });
      });
    });
  };

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // Delete User Session.  * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

  /** Dispatches the START sequence to end users session. */
  dispatchDeleteUserSession = (): void =>
    this.store.dispatch(
      actions.authDeleteUserSessionStart({ payload: { longTermToken: this.storageService.getLongToken() } }),
    );

  /**
   * End the users session by invalidating the "Long Term" token on the Back-end.
   */
  deleteUserSession = (payload: AuthDeleteUserSessionRequest): Observable<Record<string, unknown>> => {
    // this.returnBiometrics(credentials => {
    if (Capacitor.isNativePlatform()) {
      // If the current long term token is saved to the app keychain then don't delete it otherwise biometric and pin login will fail due to and invalid longTermToken
      return of({});
    } else {
      /**
       * By default, Angular does NOT allow a "body" on delete requests. Writing
       * the request in this format is a hack to allow the "body" in the options
       * overload.
       *
       * @see https://github.com/angular/angular/issues/19438
       */
      return this.http.request<Record<string, unknown>>('delete', `${environment.endpointApiTwo}/v2/login`, {
        body: payload,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
    // });
  };

  /**
   * Handle the SUCCESS sequence after the request to end the users session has
   * returned from the server.
   */
  handleDeleteUserSessionSuccess = (): void => {
    this.actions$.pipe(ofType(actions.authDeleteUserSessionSuccess)).subscribe(({ manualLogout }) => {
      /**
       * Remove the "Short / Long Term" tokens from local storage as the have
       * been invalidated on the server.
       */
      this.storageService.clearUserId();
      this.storageService.clearLongToken();
      this.storageService.clearShortToken();
      this.clearIntercom();

      /**
       * Send the user back to the /login screen where they will need to re-authenticate
       * again to gain access to gated content.
       */
      void this.router.navigate(['/login'], { state: { manualLogout } });
    });
  };

  setPendingNotification = (event?: NotificationActionPerformedEvent): void => {
    // Save action to pending state
    // this.pendingNotification = { ...event };
    const notificationData = event?.notification?.data as NotificationEventData;
    if (environment.rayGunApiKey) {
      rg4js('send', {
        error: new Error('set PendingNotification'),
        customData: event?.notification,
      });
    }
    Preferences.set({ key: PreferenceStorage.PendingNotification, value: JSON.stringify(notificationData) });
    // Check if user is logged in
    if (this.checkIsLoggedIn()) {
      if (environment.rayGunApiKey) {
        rg4js('send', {
          error: new Error('checkIsLoggedIn === true'),
          customData: event?.notification,
        });
      }
      // Confirm that token are still valid
      this.getCheckAuthTokens(successful => successful && this.actionNotification());
    }
  };

  getPendingNotification = (callback: (notificationData: NotificationEventData) => void): void => {
    // Save action to pending state
    Preferences.get({ key: PreferenceStorage.PendingNotification }).then(notificationData => {
      callback(notificationData.value ? (JSON.parse(notificationData.value) as NotificationEventData) : undefined);
    });
  };

  actionNotification = (fallbackRoute?: string): void => {
    if (environment.rayGunApiKey) {
      rg4js('send', {
        error: new Error('actionNotification'),
      });
    }
    this.ngZone.run(() => {
      this.getPendingNotification(pendingNotification => {
        if (environment.rayGunApiKey) {
          rg4js('send', {
            error: new Error('getPendingNotification'),
            customData: pendingNotification,
          });
        }
        this.clearPendingNotification();
        if (pendingNotification) {
          /**
           * then redirect the user to the correct page based on the notificationAction
           */
          const redirectRoute = returnNotificationRoute({
            notificationAction: pendingNotification?.Action,
            entityId: pendingNotification?.EntityId,
            investmentId: pendingNotification?.InvestmentId,
            investmentOfferId: pendingNotification?.InvestmentOfferId,
            investmentOrderId: pendingNotification?.InvestmentOrderId,
          });
          if (environment.rayGunApiKey) {
            rg4js('send', {
              error: new Error('redirectRoute'),
              customData: redirectRoute,
            });
          }
          if (redirectRoute) {
            void this.router.navigate(redirectRoute);
          } else {
            if (fallbackRoute) {
              void this.router.navigate([fallbackRoute]);
            }
            this.fullScreenDialog.closeAll();
            if (environment.rayGunApiKey) {
              rg4js('send', {
                error: new Error('openDialog'),
                customData: { fallbackRoute },
              });
            }
            this.fullScreenDialog.openDialog(NotificationsComponent, { maxWidth: 600, closeOnNavigation: true });
          }
        }
      });
    });
  };

  clearPendingNotification = (): void => {
    Preferences.remove({ key: PreferenceStorage.PendingNotification });
  };

  setRedirectUrl = (url?: string): void => {
    /**
     * Get the current url and set redirectUrl
     */
    const { origin, pathname } = window.location;

    if (url === undefined) {
      // if not passed as a param then get the current pathname
      url = pathname;
    }

    if (url?.length && url !== '/login') {
      // Only save url if its defined
      // and is not /login as this will cause a loop
      this.redirectUrl = new URL(`${origin}${url}`);
    } else {
      // if url is undefined the reset any previous urls
      this.resetRedirectUrl();
    }
  };

  resetRedirectUrl = (): void => {
    /**
     * reset redirectUrl back to undefined
     */
    this.redirectUrl = undefined;
  };

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // Delete User Account.  * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

  /** Dispatches the START sequence to close the users account. */
  dispatchDeleteUserAccount = (): void => this.store.dispatch(actions.authDeleteUserAccountStart());

  /** Close the users account by targeting a logged in user by their User ID. */
  deleteUserAccount = (): Observable<AuthDeleteUserAccountRequest> => {
    const userId = this.storageService.getUserId();

    return this.http.post<AuthDeleteUserAccountRequest>(
      `${environment.endpointApiOne}/v1/users/${userId}/deactivate`,
      {},
      {
        headers: this.createAuthHeaders(),
      },
    );
  };

  /**
   * Handle the SUCCESS sequence after the request to close the users account has
   * returned from the server.
   */
  handleDeleteUserAccountSuccess = (): void => {
    this.actions$.pipe(ofType(actions.authDeleteUserAccountSuccess)).subscribe(() => {
      /**
       * Delete ALL local "persistent" references of the users account so that
       * they appear as a blank slate.
       */
      this.storageService.clearUserId();
      this.storageService.clearLongToken();
      this.storageService.clearShortToken();
      this.clearIntercom();

      /**
       * Send the user back to the /login screen where they will need to signup
       * from scratch as a brand new user again to gain access to gated content.
       */
      void this.router.navigate(['/login'], { state: { manualLogout: true } });
    });
  };

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // Check if the native app version is still valid  * * * * * * * * * * * * * *
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

  checkNativeVersionIsValid(callback?: (successful: boolean) => void): void {
    this.http
      .post<AuthDeviceCheckinResponse>(
        `${environment.endpointApiOne}/v2/devices/check-in`,
        { platform: Capacitor.getPlatform(), version: packageInfo?.version },
        {
          headers: this.createAuthHeaders(),
        },
      )
      .pipe(take(1))
      .subscribe(
        response => {
          if (response?.updateRequired) {
            this.dialog.open(VersionUpdateDialogComponent, {
              autoFocus: false,
              width: '400px',
              closeOnNavigation: false,
              disableClose: true,
              data: { optional: true, message: response?.updateMessage } as VersionUpdateDialogData,
            });
          } else if (response?.disabled) {
            this.dialog.open(VersionUpdateDialogComponent, {
              autoFocus: false,
              width: '400px',
              closeOnNavigation: false,
              disableClose: true,
              data: { optional: false, message: response?.disabledMessage } as VersionUpdateDialogData,
            });
          }

          callback && callback(true);
        },
        () => {
          callback && callback(false);
        },
      );
  }

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // Check auth tokens  * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

  getCheckAuthTokens(callback: (successful: boolean) => void): void {
    // Quick api call to confirm if auth tokens are still valid
    this.http
      .get<unknown>(`${environment.endpointApiOne}/v1/investments/rates`, {
        headers: this.createAuthHeaders(),
      })
      .pipe(take(1))
      .subscribe(
        () => {
          callback(true);
        },
        () => {
          callback(false);
        },
      );
  }

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // Register User.  * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  dispatchRegisterUser = (payload: AuthRegisterUserItemTemplate): void => {
    this.store.dispatch(
      actions.authSendRegisterUserStart({
        payload: {
          ...convertRegisterTemplateIntoRequestData(payload),
          clientId: environment.clientId,
        },
      }),
    );
  };

  registerUser = (payload: AuthRegisterUserRequest): Observable<AuthRegisterUserResponse> => {
    return this.http.post<AuthRegisterUserResponse>(`${environment.endpointApiTwo}/v2/register`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };

  handleRegisterUserSuccess = (): void => {
    this.actions$.pipe(ofType(actions.authSendRegisterUserSuccess)).subscribe(({ payload }) => {
      /**
       * Cache the tokens in local storage
       */
      const { userId, longTermToken, shortTermToken } = payload;
      this.brazeService.setCustomEvent({
        eventName: BrazeEventEnum.InvestorRegistered,
        userId: userId.toString(),
      });
      this.setUser(`${userId}`);
      this.storageService.setLongToken(longTermToken);
      this.storageService.setShortToken(shortTermToken);

      // When a user session is created fetch user and userVerfify data
      this.store.dispatch(actions.authFetchUserSessionStart({ userId }));
      this.store.dispatch(actions.authFetchUserVerifyStart());

      this.actions$.pipe(ofType(actions.authFetchUserVerifySuccess), take(1)).subscribe(() => {
        /**
         * route to validate id
         */
        void this.router.navigate([CREATE_ENTITY_FLOW_ROUTE_PREFIX, 'validate-id']);
      });
    });
  };

  handleRegisterUserFail = (callback: () => void): void => {
    this.actions$.pipe(ofType(actions.authSendRegisterUserFail)).subscribe(() => {
      callback();
    });
  };

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // Forgot Password.  * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  dispatchForgotPassword = (payload: AuthForgotPasswordRequest): void => {
    this.store.dispatch(actions.authSendForgotPasswordStart({ payload }));
  };

  forgotPassword = (payload: AuthForgotPasswordRequest): Observable<Record<string, unknown>> => {
    return this.http.post<Record<string, unknown>>(`${environment.endpointApiOne}/v1/account/forgotpassword`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };

  handleForgotPasswordSuccess = (callback: () => void): void => {
    this.actions$.pipe(ofType(actions.authSendForgotPasswordSuccess)).subscribe(() => {
      callback();
    });
  };

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // Change Password.  * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

  changePassword = (payload: AuthChangePasswordRequest): Observable<Record<string, unknown>> => {
    return this.http.post<Record<string, unknown>>(`${environment.endpointApiOne}/v1/account/updatepassword`, payload, {
      headers: this.createAuthHeaders(),
    });
  };

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // Confirm Email.  * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

  getUserMfa(): Observable<UserMfaResponse> {
    return this.http.get<UserMfaResponse>(`${environment.endpointApiOne}/v1/users/mfa`, {
      headers: this.createAuthHeaders(),
    });
  }

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // Confirm Email.  * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

  confirmEmail = (payload: AuthConfirmEmailRequest): Observable<Record<string, unknown>> => {
    return this.http.post<Record<string, unknown>>(`${environment.endpointApiOne}/v1/account/confirmemail`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // Reset Password.  * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  dispatchResetPassword = (payload: AuthResetPasswordRequest): void => {
    this.store.dispatch(actions.authSendResetPasswordStart({ payload }));
  };

  resetPassword = (payload: AuthResetPasswordRequest): Observable<Record<string, unknown>> => {
    return this.http.post<Record<string, unknown>>(`${environment.endpointApiOne}/v1/account/resetpassword`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };

  handleResetPasswordSuccess = (callback: () => void): void => {
    this.actions$.pipe(ofType(actions.authSendResetPasswordSuccess)).subscribe(() => {
      callback();
    });
  };

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // Register device  * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  registerFirebaseToken(payload: registerDeviceRequest): Observable<unknown> {
    return this.http.post(
      `${environment.endpointApiOne}/v3/devices/register`,
      { pushServerId: PushServerIdEnum.Hybrid, pushDeviceToken: payload?.pushDeviceToken },
      {
        headers: this.createAuthHeaders(),
      },
    );
  }

  revokeFirebaseToken(payload: registerDeviceRequest): Observable<unknown> {
    return this.http.post(
      `${environment.endpointApiOne}/v3/devices/revoke`,
      { pushServerId: PushServerIdEnum.Hybrid, pushDeviceToken: payload?.pushDeviceToken },
      {
        headers: this.createAuthHeaders(),
      },
    );
  }

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // Set intial Password.  * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  dispatchSetInitialPassword = (payload: AuthSetInitialPasswordRequest): void => {
    this.store.dispatch(actions.authSendSetInitialPasswordStart({ payload }));
  };

  setInitialPassword = (payload: AuthSetInitialPasswordRequest): Observable<AuthSetInitialPasswordResponse> => {
    return this.http.post<AuthSetInitialPasswordResponse>(
      `${environment.endpointApiOne}/v1/account/setinitialpassword`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  };

  handleSetInitialPasswordSuccess = (callback: () => void): void => {
    this.actions$.pipe(ofType(actions.authSendSetInitialPasswordSuccess)).subscribe(response => {
      /**
       * Cache the tokens in local storage
       */
      const { userId, longTermToken, shortTermToken } = response.payload;
      this.setUser(`${userId}`);
      this.storageService.setLongToken(longTermToken);
      this.storageService.setShortToken(shortTermToken);

      // When a user session is created fetch user and userVerfify data
      this.store.dispatch(actions.authFetchUserSessionStart({ userId }));
      this.store.dispatch(actions.authFetchUserVerifyStart());

      callback();

      this.actions$.pipe(ofType(actions.authFetchUserSessionSuccess), take(1)).subscribe(() => {
        /**
         * route to the dashboard
         */
        void this.router.navigate(['/']);
      });
    });
  };

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // Check has Password.  * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  dispatchHasPassword = (payload: AuthHasPasswordRequest): void => {
    this.store.dispatch(actions.authSendHasPasswordStart({ payload }));
  };

  checkHasPassword = (payload: AuthHasPasswordRequest): Observable<AuthHasPasswordResponse> => {
    return this.http.post<AuthHasPasswordResponse>(`${environment.endpointApiOne}/v1/account/haspassword`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };

  handleHasPasswordSuccess = (callback: (response: AuthHasPasswordResponse) => void): void => {
    this.actions$.pipe(ofType(actions.authSendHasPasswordSuccess)).subscribe(response => {
      callback(response?.payload);
    });
  };

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // Generic Request.  * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

  createAuthHeaders = (): { 'Content-Type': string; Authorization: string } => ({
    'Content-Type': 'application/json',
    Authorization: `Token ${this.storageService.getShortToken()}`,
  });

  createAuthHeadersFile = (filename: string): { 'Content-Disposition': string; Authorization: string } => ({
    // spaces in filename need to be replaced with - otherwise the call will fail
    'Content-Disposition': `attachment; filename="${filename}"`,
    Authorization: `Token ${this.storageService.getShortToken()}`,
  });

  /**
   * DEPRECATED
   * Creates an NGRX Effect callback that has the ability to make the bulk of
   * Squirrels generic data requests.
   *
   * The reason we have abstracted this functionality is due to the "Short Token"
   * implementation. There is a level of consistent complexity that requires
   * recursion, token invalidation etc that make sense to write once and use
   * everywhere.
   *
   * This is an updated typesafe version
   */
  createGenericRequest =
    ({
      actions$,
      makeRequest,
      successMessage,
      errorMessage,
      startAction,
      successAction,
      failAction,
      useMergeMap,
      passThroughPayloadOnSuccess,
    }: CreateGenericRequest) =>
    (): Observable<any> => {
      // Define the mapping operator
      let mapOperator = switchMap;
      if (useMergeMap) {
        mapOperator = mergeMap;
      }
      /**
       * Because we rely on a recursive setup when "authenticating" BEFORE "fetching"
       * data. We can find the application in a recursive loop - this is an error
       * state and we need to reset the session.
       *
       *       . -> -[GET SHORT TERM TOKEN]> -> .
       *       ^                                v
       *       ^                        [GET GENERIC DATA]-> -> [SUCCESS]-> -> 🎉
       *       ^                                v
       *       . <- <- <- <-[ERROR] <- <- <- <- .
       *                 (Get new token
       *                 and try again)
       *
       * In that regard we try fetching the data "X" times before logging the user
       * out and resetting the recursive attempts for next time.
       */
      const asyncRecursion = (() => {
        let currentAttempt = 1;
        const maxAttempts = 5;

        return {
          current: () => currentAttempt,
          increment: () => (currentAttempt += 1),
          reset: () => (currentAttempt = 1),
          shouldDestroy: () => currentAttempt >= maxAttempts,
        };
      })();

      return actions$.pipe(
        /** Subscribe to the supplied "START" action. */
        ofType(startAction.type),
        mapOperator((action: any) => {
          /**
           * Before we make the API request we need our "Short Term" token. This
           * function will either return it from local storage OR make a request to
           * the server for a new one.
           */
          return this.createShortTermToken().pipe(
            mapOperator(() => {
              /** After getting our "Short Term" token we can make our API request. */
              return makeRequest(action).pipe(
                map(payload => {
                  /**
                   * Recursive sequence has finished to we reset this API to start
                   * fresh on the next request.
                   */
                  asyncRecursion.reset();

                  /**
                   * If the configuration has set a SUCCESS message then we show it
                   * here
                   */
                  if (successMessage) {
                    this.snackBarService.addSuccess(successMessage);
                  }
                  /**
                   * If the configuration has set passThroughPayloadOnSuccess then overide the reponse payload with the request payload,
                   * this is useful when there is no response payload e.g a delete request and you need to identify the deleted item
                   */
                  if (passThroughPayloadOnSuccess) {
                    payload = action.payload;
                  }

                  /**
                   * Return the configurations SUCCESS action with the corresponding
                   * payload.
                   */
                  return { type: successAction.type, payload };
                }),
              );
            }),
            catchError(error => {
              return this.treatError(error, asyncRecursion, errorMessage, startAction, failAction.type);
            }),
          );
        }),
      );
    };

  /**
   * DEPRECATED use signalsTreatError() instead
   */
  treatError = (
    error,
    asyncRecursion: {
      current: () => number;
      increment: () => number;
      reset: () => number;
      shouldDestroy: () => boolean;
    },
    errorMessage?: string,
    action?: { type: string; payload?: any },
    failAction?: string,
  ): Observable<{ type: string; payload?: any }> => {
    let message: string;

    // TODO: remove after back-end team fix the return msg for this scenario
    // do not throw toast msg error
    if (error?.errors && error.errors[0] && error.errors[0].propertyName === 'isOfferExpired') {
      return of({ type: failAction, payload: error });
    }

    /**
     * If we determine that we ARE in an infinite recursive request loop
     * then bail out and reset the users session to an unauthenticated state.
     */
    if (asyncRecursion.shouldDestroy()) {
      asyncRecursion.reset();
      message = 'Sorry, we have encountered an error and ended your session';
      this.snackBarService.setError(message);
      this.loggerService.logEvent({ message, errorResponse: error });

      this.setRedirectUrl();
      return of(actions.authDeleteUserSessionSuccess({}));
    }

    /**
     * Based on the error code we need to "actually" fail out the
     * request OR "re-make" the request and get a new token.
     *
     * Also we should clear the token so that when the request sequence
     * recurses we know to fetch a new token.
     */
    let status: number;
    let errMsg: string;

    if (error?.error?.errors && error.error.errors[0]) {
      status = +error.error.errors[0].errorCode;
      errMsg = error.error.errors[0].errorMessage;
    } else if (error?.errors && error?.errors[0]) {
      status = +error.errors[0].errorCode;
      errMsg = error.errors[0].errorMessage;
    } else {
      status = error.status;
      errMsg = error.error;
    }

    const url = error.url;
    const is401 = status === 401;
    const is500 = status === 500;
    const isInvalidFormValue = status?.toString() === SpecialErrorCodes.InvalidValue;
    const isShortTokenError = is401 && !url?.includes('/v2/login/refresh');
    const isLongTokenError = is401 && url?.includes('/v2/login/refresh');

    if (is401 && errMsg === 'Authentication expired') {
      // probably came from some endpoints that are returning status 401
      // but original error null - we force logout
      asyncRecursion.reset();
      // Marita requested to disable this msg
      // this.snackBarService.setError('Sorry, your session has ended. Please log back in');

      this.setRedirectUrl();
      return of(actions.authDeleteUserSessionSuccess({}));
    }

    if (isShortTokenError) {
      /**
       * This is a "new" attempt to fetch the requested data payload so
       * bump the attempt reference. We have no idea how many times...
       * but we bail out after "X" ERROR'ed attempts.
       */

      asyncRecursion.increment();
      this.storageService.clearShortToken();
      return of(action);
    } else if (isLongTokenError) {
      asyncRecursion.reset();

      this.setRedirectUrl();
      return of(actions.authDeleteUserSessionSuccess({}));
    }

    if (is500) {
      // bad request, database down, back-end APIs not available or timeout for some reason
      asyncRecursion.reset();
      this.loggerService.logEvent({ message: 'back-end returned 500', errorResponse: error });
      // This is the one place we use a hard coded phone number as we may not have the constant data from the api call available
      this.snackBarService.setError(
        'Oops, something went wrong. \n\n \
      Please contact Squirrel at 0800 21 22 30',
      );
      return of({ type: failAction, payload: error });
    }

    if (error.status == SpecialErrorCodes.MfaRequired) {
      // Do not show any errors for MFA failures communication for this is handled by AuthSignal
      asyncRecursion.reset();
      return of({ type: failAction, payload: error });
    }

    asyncRecursion.reset();

    if (!isInvalidFormValue) {
      // Do not show a toast message if the error comes from an invalid form input
      if (errorMessage?.length > 0) {
        this.snackBarService.setError(errorMessage);
      } else if (error?.errors[0]?.errorMessage?.length > 0) {
        this.snackBarService.setError(error?.errors[0]?.errorMessage);
      }
    }

    return of({ type: failAction, payload: error });
  };

  /**
   * Alternative error handler for usage in new signal store implementations.
   *
   * It replaces the usage of createGenericRequest() and treatError()
   * that require the use of observables and are't compatable with ngrx signal methods.
   *
   * Its also removes unnecessary asyncRecursion code.
   */
  signalsTreatError = (error, defaultErrorMessage?: string): Observable<{ error?: any }> => {
    // TODO: remove after back-end team fix the return msg for this scenario
    // do not msg error
    if (error?.errors && error.errors[0] && error.errors[0].propertyName === 'isOfferExpired') {
      return of({ error });
    }

    let status: number;
    let errMsg: string;

    if (error?.error?.errors && error.error.errors[0]) {
      status = +error.error.errors[0].errorCode;
      errMsg = error.error.errors[0].errorMessage;
    } else if (error?.errors && error?.errors[0]) {
      status = +error.errors[0].errorCode;
      errMsg = error.errors[0].errorMessage;
    } else {
      status = error.status;
      errMsg = error.error;
    }

    const isInvalidFormValue = status?.toString() === SpecialErrorCodes.InvalidValue;

    if (status === 401 && errMsg === 'Authentication expired') {
      // probably came from some endpoints that are returning status 401
      // but original error null - we force logout

      this.setRedirectUrl();
      this.store.dispatch(actions.authDeleteUserSessionSuccess({}));
    }

    if (status === 500) {
      // bad request, database down, back-end APIs not available or timeout for some reason
      this.loggerService.logEvent({ message: 'back-end returned 500', errorResponse: error });
      // This is the one place we use a hard coded phone number as we may not have the constant data from the api call available
      this.snackBarService.setError(
        'Oops, something went wrong. \n\n \
      Please contact Squirrel at 0800 21 22 30',
      );
      return of({ error });
    }

    if (error.status == SpecialErrorCodes.MfaRequired) {
      // Do not show any errors for MFA failures communication for this is handled by AuthSignal
      return of({ error });
    }

    if (!isInvalidFormValue) {
      // Do not show a toast message if the error comes from an invalid form input
      if (defaultErrorMessage?.length > 0) {
        this.snackBarService.setError(defaultErrorMessage);
      } else if (error?.errors[0]?.errorMessage?.length > 0) {
        this.snackBarService.setError(error?.errors[0]?.errorMessage);
      }
    }

    return of({ error });
  };

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // Fetch User  * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  fetchUser(userId: number): Observable<AuthUserResponse> {
    return this.http.get<AuthUserResponse>(`${environment.endpointApiOne}/v1/users/${userId}`, {
      headers: this.createAuthHeaders(),
    });
  }

  fetchUserVerify(): Observable<IUserVerifyV2> {
    return this.http.get<IUserVerifyV2>(`${environment.endpointApiOne}/v2/users/verify`, {
      headers: this.createAuthHeaders(),
    });
  }

  // User ID.
  /** Set a new User ID */
  setUser = (userId: string): void => {
    this.brazeService.setCustomEvent({
      eventName: BrazeEventEnum.LoggedIn,
      userId,
    });

    this.nativeAuthService.linkFirebaseTokenToUser();

    this.analyticsService.setUser(userId);

    Intercom.loginIdentifiedUser({
      userId,
    });

    Preferences.set({ key: PreferenceStorage.ReturningUser, value: 'true' });

    this.storageService.setUserId(userId);
  };

  lanchNewUserSteps = (): void => {
    Preferences.get({ key: PreferenceStorage.IntroBiometric }).then(introBiometric => {
      // Check that the biometric step hasn't already been done
      const introBiometricStepIncomplete = introBiometric?.value === null;
      Preferences.get({ key: PreferenceStorage.IntroMFA }).then(introMFA => {
        // Check that the MFA intro step hasn't already been done
        const introMFAStepIncomplete = introMFA?.value === null;
        Preferences.get({ key: PreferenceStorage.IntroPin }).then(introPin => {
          // Check that the MFA intro step hasn't already been done
          const introPinStepIncomplete = introPin?.value === null;

          if (introBiometricStepIncomplete || introMFAStepIncomplete || introPinStepIncomplete) {
            this.getUserMfa()
              .pipe(take(1))
              .subscribe(userMfaResponse => {
                let steps: IntroStepEnum[] = [];
                if (
                  Capacitor.isNativePlatform() &&
                  this.nativeAuthService.nativeBiometricAvailable &&
                  introBiometricStepIncomplete
                ) {
                  // Check that biometrics is available
                  steps = [...steps, IntroStepEnum.Biometric];
                }

                if (Capacitor.isNativePlatform() && introPinStepIncomplete) {
                  steps = [...steps, IntroStepEnum.Pin];
                }

                if (userMfaResponse?.isEnrolled === false && introMFAStepIncomplete) {
                  // Check to see if they haven't already enrolled in MFA
                  steps = [...steps, IntroStepEnum.MFA];
                }
                if (steps.length && this.newUserIntroDialogOpen !== true) {
                  const dialogRef = this.dialog.open(NewUserStepDialogComponent, {
                    panelClass: 'new-user-overlay',
                    autoFocus: false,
                    data: { steps },
                  });
                  this.newUserIntroDialogOpen = true;

                  dialogRef
                    .afterClosed()
                    .pipe(take(1))
                    .subscribe(enableBiometric => {
                      if (enableBiometric !== true) {
                        this.newUserIntroDialogOpen = false;
                      }
                    });
                }
              });
          }
        });
      });
    });
  };

  /** Clear the current User ID */
  clearIntercom = (): void => {
    Intercom.logout();
  };
}
