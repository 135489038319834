import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-new-user-step-actions',
  standalone: true,
  imports: [MatButton],
  template: `
    @if (primaryLabel) {
      <button
        mat-flat-button
        color="primary"
        type="button"
        (click)="buttonClicked.emit('primary')"
        data-testid="primary-button"
        class="action-button"
      >
        {{ primaryLabel }}
      </button>
    }
    @if (secondaryLabel) {
      <button
        mat-button
        type="button"
        data-testid="secondary-button"
        (click)="buttonClicked.emit('secondary')"
        class="action-button"
      >
        {{ secondaryLabel }}
      </button>
    }
  `,
  styleUrls: ['./new-user-step-actions.component.scss'],
})
export class NewUserStepActionsComponent {
  @Input() primaryLabel?: string;
  @Input() secondaryLabel?: string;
  @Output() buttonClicked = new EventEmitter<'primary' | 'secondary'>();
}

export type NewUserStepActionEvent = 'primary' | 'secondary';
