import { Component, Inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { Capacitor } from '@capacitor/core';

import { AppStoreURLS, CapacitorPlatformTypes } from '@core/capacitor/capacitor.types';

@Component({
  standalone: true,
  imports: [MatDialogModule, MatButton],
  selector: 'app-version-update-dialog',
  template: ` <div mat-dialog-content>
    <h2 class="mat-headline-6">{{ data?.message }}</h2>
    <div class="actions">
      <a
        [href]="platform === platformTypes.Android ? appStoreURLS?.GooglePlay : appStoreURLS?.AppStore"
        target="_blank"
        mat-flat-button
        color="primary"
        class="action-button"
        type="button"
        (click)="openAppStore()"
      >
        Update now
      </a>
      @if (data?.optional) {
        <button mat-flat-button class="action-button" type="button" mat-dialog-close>I'll do it later</button>
      }
    </div>
  </div>`,
  styleUrls: ['./version-update-dialog.component.scss'],
})
export class VersionUpdateDialogComponent {
  platform = Capacitor.getPlatform();
  platformTypes = CapacitorPlatformTypes;
  appStoreURLS = AppStoreURLS;
  constructor(
    public dialogRef: MatDialogRef<VersionUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: VersionUpdateDialogData,
  ) {}

  openAppStore(): void {
    this.dialogRef.close(true);
  }
}

export interface VersionUpdateDialogData {
  optional: boolean;
  message: string;
}
