import { environment } from '@environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthService } from '@views/misc/auth/auth.service';

import { MarketplaceFetchResponse, ResponseBrochureStatistics } from './statistics.types';

@Injectable({
  providedIn: 'root',
})
export class BrochureStatisticsService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  fetchStatisticsBrochure(): Observable<ResponseBrochureStatistics> {
    return this.http.get<ResponseBrochureStatistics>(`${environment.endpointApiTwo}/v2/statistics/brochure`, {
      headers: this.authService.createAuthHeaders(),
    });
  }

  fetchStatistics(): Observable<ResponseBrochureStatistics> {
    return this.http.get<ResponseBrochureStatistics>(`${environment.endpointApiOne}/v1/statistics`, {
      headers: this.authService.createAuthHeaders(),
    });
  }

  fetchMarketplaceCustomValues(): Observable<MarketplaceFetchResponse> {
    return this.http.get<MarketplaceFetchResponse>(
      `${environment.endpointApiOne}/v1/content/marketplaceStatistics/marketplace`,
    );
  }
}
