import { Injectable } from '@angular/core';

import { Preferences } from '@capacitor/preferences';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, of, switchMap } from 'rxjs';

import { loginStageUpdate } from '@views/login/login.actions';
import { LoginStageEnum } from '@views/login/login.types';

import { SnackBarService } from '@components/snackbar/snackbar.service';

import { LoggerService } from '@core/utilities/logger/logger.service';

import * as actions from './auth.actions';
import { AuthService } from './auth.service';
import { NativeLoginTypes, PreferenceStorage } from './auth.types';
import { NativeAuthService } from './native-auth.service';
import { StorageService } from './storage.service';

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private nativeAuthService: NativeAuthService,
    private snackBarService: SnackBarService,
    private loggerService: LoggerService,
    private storageService: StorageService,
  ) {}

  createUserSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.authCreateUserSessionStart),
      switchMap(action => {
        return this.authService.createUserSession(action.payload).pipe(
          map(payload => {
            return actions.authCreateUserSessionSuccess({ payload });
          }),
          catchError(error => {
            let errorMessage = '';

            if (error.errors) {
              errorMessage = error.errors[0].errorMessage;
            } else {
              errorMessage = 'something went wrong in our systems, please try again later';
              if (error?.error?.errorMessage?.length > 0) {
                errorMessage = error.error.errorMessage;
              }
              this.loggerService.logEvent({ message: errorMessage, errorResponse: error });
              this.snackBarService.setError(errorMessage);
            }
            return of(
              actions.authCreateUserSessionFail({
                payload: errorMessage,
              }),
            );
          }),
        );
      }),
    ),
  );

  refreshShortTermTokenToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.authRefreshShortTermTokenStart),
      switchMap(action => {
        const { longTermToken } = action;
        return this.authService.refreshShortTermToken({ longTermToken }).pipe(
          map(payload => {
            this.storageService.setShortToken(payload?.shortTermToken);
            return actions.authRefreshShortTermTokenSuccess();
          }),
        );
      }),
    ),
  );

  createUserSessionFromLongTermToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.authCreateUserSessionFromLongTermTokenStart),
      switchMap(action => {
        const { longTermToken, userId, loginType } = action;
        return this.authService.refreshShortTermToken(action).pipe(
          map(payload => {
            return actions.authCreateUserSessionSuccess({
              payload: {
                longTermToken,
                shortTermToken: payload?.shortTermToken,
                shortTermTokenExpiresInSeconds: payload?.shortTermTokenExpiresInSeconds,
                userId,
              },
            });
          }),
          catchError(() => {
            if (loginType === NativeLoginTypes.Biometric) {
              this.snackBarService.setError(
                "It's been more than 90 days since you've used biometrics to login, please use your username and password.",
              );
              // Clear the biometric so the user has to re-enable it
              this.nativeAuthService.disableBiometricLogin();
              // Reset the intro biometric flag so that they are asked to re enable it after they login via the form
              Preferences.remove({ key: PreferenceStorage.IntroBiometric });
            } else if (loginType === NativeLoginTypes.Pin) {
              this.snackBarService.setError(
                "It's been more than 90 days since you've used you're pin to login, please use your username and password.",
              );
              // Clear the pin so the user has to re-enable it
              this.nativeAuthService.disablePinLogin();
              // Reset the intro pin flag so that they are asked to reset the pin after they login via the form
              Preferences.remove({ key: PreferenceStorage.IntroPin });
            }

            return of(
              // Reset the login flow to be the login form
              loginStageUpdate({
                loginStage: LoginStageEnum.LoginForm,
              }),
            );
          }),
        );
      }),
    ),
  );

  fetchUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.authFetchUserSessionStart),
      switchMap(payload => {
        return this.authService.fetchUser(payload.userId).pipe(
          map(response => {
            return actions.authFetchUserSessionSuccess({ user: response });
          }),
          catchError(() => {
            return of(actions.authFetchUserSessionFail());
          }),
        );
      }),
    ),
  );

  fetchUserVerify$ = createEffect(
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: () => this.authService.fetchUserVerify(),
      errorMessage: 'Sorry, there has been an error closing your user data',
      startAction: actions.authFetchUserVerifyStart,
      successAction: actions.authFetchUserVerifySuccess,
      failAction: actions.authFetchUserVerifyFail,
    }),
  );

  deleteUserSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.authDeleteUserSessionStart),
      switchMap(action => {
        return this.authService.deleteUserSession(action.payload).pipe(
          map(() => {
            // this.snackBarService.addSuccess('You have been logged out');
            return actions.authDeleteUserSessionSuccess({ manualLogout: true });
          }),
          catchError(error => {
            this.loggerService.logEvent({ message: 'error logging out: ', errorResponse: error });
            try {
              // if fail for some reason, let's use brute force and
              // remove the tokens. Doing this the application will
              // route to login page.
              this.storageService.clearLongToken();
              this.storageService.clearShortToken();
              this.storageService.clearUserId();
              this.authService.clearIntercom();
              return of(actions.authDeleteUserSessionSuccess({ manualLogout: true }));
            } catch (e) {
              // if still fails, there is nothing we can do :/
              // let's abort mission
              this.loggerService.logEvent({ message: 'error logging out: ', errorResponse: e });

              this.snackBarService.setError('Sorry, there has been an error logging you out');
              return of(actions.authDeleteUserSessionFail());
            }
          }),
        );
      }),
    ),
  );

  deleteUserAccount$ = createEffect(
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: () => this.authService.deleteUserAccount(),
      errorMessage: 'Sorry, there has been an error closing your account',
      startAction: actions.authDeleteUserAccountStart,
      successAction: actions.authDeleteUserAccountSuccess,
      failAction: actions.authDeleteUserAccountFail,
    }),
  );

  confirmEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.authConfirmEmailStart),
      switchMap(action => {
        return this.authService.confirmEmail(action.payload).pipe(
          map(() => {
            return actions.authConfirmEmailSuccess();
          }),
          catchError(() => {
            return of(actions.authConfirmEmailFail());
          }),
        );
      }),
    ),
  );

  registerUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.authSendRegisterUserStart),
      switchMap(action => {
        return this.authService.registerUser(action.payload).pipe(
          map(payload => {
            return actions.authSendRegisterUserSuccess({
              payload,
            });
          }),
          catchError(error => {
            this.snackBarService.setError('Sorry, there was an error sending this form');

            this.loggerService.logEvent({
              message: 'there was an error sending this form - register user',
              errorResponse: error,
            });
            return of(actions.authSendRegisterUserFail());
          }),
        );
      }),
    ),
  );

  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.authSendForgotPasswordStart),
      switchMap(action => {
        return this.authService.forgotPassword(action.payload).pipe(
          map(() => {
            return actions.authSendForgotPasswordSuccess();
          }),
          catchError(error => {
            this.loggerService.logEvent({
              message: 'there was an error sending this form - forgot password - error sending email',
              errorResponse: error,
            });
            this.snackBarService.setError('Sorry, there was an error sending your email address');
            return of(actions.authSendForgotPasswordFail());
          }),
        );
      }),
    ),
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.authSendResetPasswordStart),
      switchMap(action => {
        return this.authService.resetPassword(action.payload).pipe(
          map(() => {
            return actions.authSendResetPasswordSuccess();
          }),
          catchError(error => {
            this.loggerService.logEvent({ message: 'there was an error resetting password', errorResponse: error });
            this.snackBarService.setError('Sorry, there was an error resetting your password');
            return of(actions.authSendResetPasswordFail());
          }),
        );
      }),
    ),
  );

  setInitialPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.authSendSetInitialPasswordStart),
      switchMap(action => {
        return this.authService.setInitialPassword(action.payload).pipe(
          map(payload => {
            return actions.authSendSetInitialPasswordSuccess({ payload });
          }),
          catchError(error => {
            this.loggerService.logEvent({ message: 'there was an error setting password', errorResponse: error });
            this.snackBarService.setError('Sorry, there was an error setting your password');
            return of(actions.authSendSetInitialPasswordFail());
          }),
        );
      }),
    ),
  );

  hasPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.authSendHasPasswordStart),
      switchMap(action => {
        return this.authService.checkHasPassword(action.payload).pipe(
          map(response => {
            return actions.authSendHasPasswordSuccess({ payload: response });
          }),
          catchError(error => {
            this.loggerService.logEvent({
              message: 'there was an error checking if has password',
              errorResponse: error,
            });
            this.snackBarService.setError('Sorry, there was an error checking your token');
            return of(actions.authSendHasPasswordFail());
          }),
        );
      }),
    ),
  );

  changePassword$ = createEffect(() =>
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: action => this.authService.changePassword(action.payload),
      errorMessage: 'Sorry, there was an error changing your password',
      startAction: actions.authSendChangePasswordStart,
      successAction: actions.authSendChangePasswordSuccess,
      failAction: actions.authSendChangePasswordFail,
    }),
  );

  registerFirebaseToken$ = createEffect(() =>
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: action => this.authService.registerFirebaseToken(action.payload),
      startAction: actions.authRegisterFirebaseTokenStart,
      successAction: actions.authRegisterFirebaseTokenSuccess,
      failAction: actions.authRegisterFirebaseTokenFail,
    }),
  );
}
