import { createAction, props } from '@ngrx/store';

import { IUserVerifyV2 } from '../user/user.types';
import {
  AuthChangePasswordRequest,
  AuthConfirmEmailRequest,
  AuthCreateUserSessionRequest,
  AuthCreateUserSessionResponse,
  AuthDeleteUserSessionRequest,
  AuthForgotPasswordRequest,
  AuthHasPasswordRequest,
  AuthHasPasswordResponse,
  AuthRefreshTokenRequest,
  AuthRegisterUserRequest,
  AuthRegisterUserResponse,
  AuthResetPasswordRequest,
  AuthSetInitialPasswordRequest,
  AuthSetInitialPasswordResponse,
  AuthUserResponse,
  registerDeviceRequest,
} from './auth.types';

export const authCreateUserSessionStart = createAction(
  'AUTH/CREATE_USER_SESSION_START',
  props<{ payload: AuthCreateUserSessionRequest }>(),
);
export const authCreateUserSessionFromLongTermTokenStart = createAction(
  'AUTH/CREATE_USER_SESSION_FROM_LONG_TERM_TOKEN_START',
  props<AuthRefreshTokenRequest>(),
);
export const authRefreshShortTermTokenStart = createAction(
  'AUTH/REFRESH_SHORT_TERM_TOKEN',
  props<{ longTermToken: string }>(),
);
export const authRefreshShortTermTokenSuccess = createAction('AUTH/REFRESH_SHORT_TERM_TOKEN_SUCCESS');

export const authCreateUserSessionSuccess = createAction(
  'AUTH/CREATE_USER_SESSION_SUCCESS',
  props<{ payload: AuthCreateUserSessionResponse }>(),
);
export const authCreateUserSessionFail = createAction('AUTH/CREATE_USER_SESSION_FAIL', props<{ payload: string }>());

export const authFetchUserSessionStart = createAction('AUTH/FETCH_USER_SESSION_START', props<{ userId: number }>());
export const authFetchUserSessionSuccess = createAction(
  'AUTH/FETCH_USER_SESSION_SUCCESS',
  props<{ user: AuthUserResponse }>(),
);
export const authFetchUserSessionFail = createAction('AUTH/FETCH_USER_SESSION_FAIL');

export const authFetchUserVerifyStart = createAction('AUTH/FETCH_USER_VERIFY_SESSION_START');
export const authFetchUserVerifySuccess = createAction(
  'AUTH/FETCH_USER_VERIFY_SESSION_SUCCESS',
  props<{ payload: IUserVerifyV2 }>(),
);
export const authFetchUserVerifyFail = createAction('AUTH/FETCH_USER_VERIFY_SESSION_FAIL');

export const authDeleteUserSessionStart = createAction(
  'AUTH/DELETE_USER_SESSION_START',
  props<{ payload: AuthDeleteUserSessionRequest }>(),
);
export const authDeleteUserSessionSuccess = createAction(
  'AUTH/DELETE_USER_SESSION_SUCCESS',
  props<{ manualLogout?: boolean }>(),
);
export const authDeleteUserSessionFail = createAction('AUTH/DELETE_USER_SESSION_FAIL');

export const authDeleteUserAccountStart = createAction('AUTH/DELETE_USER_ACCOUNT_START');
export const authDeleteUserAccountSuccess = createAction('AUTH/DELETE_USER_ACCOUNT_SUCCESS', props<{ payload }>());
export const authDeleteUserAccountFail = createAction('AUTH/DELETE_USER_ACCOUNT_FAIL');

export const authConfirmEmailStart = createAction(
  'AUTH/CONFIRM_EMAIL_START',
  props<{ payload: AuthConfirmEmailRequest }>(),
);
export const authConfirmEmailSuccess = createAction('AUTH/CONFIRM_EMAIL_SUCCESS');
export const authConfirmEmailFail = createAction('AUTH/CONFIRM_EMAIL_FAIL');

export const authSendRegisterUserStart = createAction(
  'AUTH/SEND_REGISTER_USER_START',
  props<{ payload: AuthRegisterUserRequest }>(),
);
export const authSendRegisterUserSuccess = createAction(
  'AUTH/SEND_REGISTER_USER_SUCCESS',
  props<{ payload: AuthRegisterUserResponse }>(),
);
export const authSendRegisterUserFail = createAction('AUTH/SEND_REGISTER_USER_FAIL');

export const authSendForgotPasswordStart = createAction(
  'AUTH/SEND_FORGOT_PASSWORD_START',
  props<{ payload: AuthForgotPasswordRequest }>(),
);
export const authSendForgotPasswordSuccess = createAction('AUTH/SEND_FORGOT_PASSWORD_SUCCESS');
export const authSendForgotPasswordFail = createAction('AUTH/SEND_FORGOT_PASSWORD_FAIL');

export const authSendChangePasswordStart = createAction(
  'AUTH/SEND_CHANGE_PASSWORD_START',
  props<{ payload: AuthChangePasswordRequest }>(),
);
export const authSendChangePasswordSuccess = createAction('AUTH/SEND_CHANGE_PASSWORD_SUCCESS');
export const authSendChangePasswordFail = createAction('AUTH/SEND_CHANGE_PASSWORD_FAIL');

export const authSendResetPasswordStart = createAction(
  'AUTH/SEND_RESET_PASSWORD_START',
  props<{ payload: AuthResetPasswordRequest }>(),
);
export const authSendResetPasswordSuccess = createAction('AUTH/SEND_RESET_PASSWORD_SUCCESS');
export const authSendResetPasswordFail = createAction('AUTH/SEND_RESET_PASSWORD_FAIL');

export const authSendSetInitialPasswordStart = createAction(
  'AUTH/SEND_SET_INITIAL_PASSWORD_START',
  props<{ payload: AuthSetInitialPasswordRequest }>(),
);
export const authSendSetInitialPasswordSuccess = createAction(
  'AUTH/SEND_SET_INITIAL_PASSWORD_SUCCESS',
  props<{ payload: AuthSetInitialPasswordResponse }>(),
);
export const authSendSetInitialPasswordFail = createAction('AUTH/SEND_SET_INITIAL_PASSWORD_FAIL');

export const authSendHasPasswordStart = createAction(
  'AUTH/SEND_HAS_PASSWORD_START',
  props<{ payload: AuthHasPasswordRequest }>(),
);
export const authSendHasPasswordSuccess = createAction(
  'AUTH/SEND_HAS_PASSWORD_SUCCESS',
  props<{ payload: AuthHasPasswordResponse }>(),
);
export const authSendHasPasswordFail = createAction('AUTH/SEND_HAS_PASSWORD_FAIL');

export const authCreateUserIdVerificationStart = createAction('AUTH/CREATE_USER_IDVERIFICATION_START');
export const authCreateUserIdVerificationSuccess = createAction(
  'AUTH/CREATE_USER_IDVERIFICATION_SUCCESS',
  props<{ payload }>(),
);
export const authCreateUserIdVerificationFail = createAction('AUTH/CREATE_USER_IDVERIFICATION_FAIL');

export const authRegisterFirebaseTokenStart = createAction(
  'AUTH/REGISTER_FIREBASE_TOKEN_START',
  props<{ payload: registerDeviceRequest }>(),
);
export const authRegisterFirebaseTokenSuccess = createAction('AUTH/REGISTER_FIREBASE_TOKEN_SUCCESS');
export const authRegisterFirebaseTokenFail = createAction('AUTH/REGISTER_FIREBASE_TOKEN_FAIL');
