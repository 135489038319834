import { Component, Inject, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { Store } from '@ngrx/store';

import { loginReset } from '@views/login/login.actions';

import { NewUserStepBiometricComponent } from './new-user-step-biometric.component';
import { NewUserStepMfaComponent } from './new-user-step-mfa.component';
import { NewUserStepPinComponent } from './new-user-step-pin.component';

export enum IntroStepEnum {
  Biometric = 'biometric',
  MFA = 'mfa',
  Pin = 'pin',
}

@Component({
  selector: 'app-new-user-step-dialog',
  standalone: true,
  imports: [
    MatButton,
    MatDialogModule,
    NewUserStepBiometricComponent,
    NewUserStepMfaComponent,
    NewUserStepPinComponent,
  ],
  template: `<div mat-dialog-content class="dialog-content">
    @switch (step) {
      @case (introStepEnum.Biometric) {
        <app-new-user-step-biometric
          [step]="data?.steps?.indexOf(step) + 1"
          [steps]="this.data?.steps?.length"
          (next)="next()"
        />
      }
      @case (introStepEnum.Pin) {
        <app-new-user-step-pin
          [step]="data?.steps?.indexOf(step) + 1"
          [steps]="this.data?.steps?.length"
          (next)="next()"
        />
      }
      @case (introStepEnum.MFA) {
        <app-new-user-step-mfa
          [step]="data?.steps?.indexOf(step) + 1"
          [steps]="this.data?.steps?.length"
          (next)="next()"
        />
      }
    }
  </div>`,
  styleUrls: ['./new-user-step-dialog.component.scss'],
})
export class NewUserStepDialogComponent implements OnInit {
  step: IntroStepEnum;
  introStepEnum = IntroStepEnum;
  constructor(
    public dialogRef: MatDialogRef<NewUserStepDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { steps: IntroStepEnum[] },
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.step = this.data?.steps[0];

    // Reset the login steps so that correct screen is shown once logged out
    this.store.dispatch(loginReset());
  }

  next() {
    const index = this.data?.steps?.indexOf(this.step);
    if (index + 1 === this.data?.steps?.length) {
      // If its the last step the close the dialog after they hit next
      this.dialogRef.close();
    } else {
      // If theres another step then change the c=urrent step to the next step
      this.step = this.data?.steps[index + 1];
    }
  }
}
