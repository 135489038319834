import { Action } from '@ngrx/store';

import { Address } from '@components/form/address/address.component';

import { CapacitorPlatformTypes } from '@core/capacitor/capacitor.types';
import { SquirrelAddress } from '@core/utilities/address';

import { IUserVerifyV2 } from '../user/user.types';

export interface AuthDeviceCheckinRequest {
  platform: CapacitorPlatformTypes;
  version: string;
}

export interface AuthDeviceCheckinResponse {
  updateRequired: boolean;
  updateMessage: string;
  disabled: boolean;
  disabledMessage: string;
}

export interface AuthUpdateShortTermTokenResponse {
  shortTermToken: string;
  shortTermTokenExpiresInSeconds: number;
}

export interface AuthCreateUserSessionResponse extends AuthUpdateShortTermTokenResponse {
  longTermToken: string;
  userId: number;
}

export type AuthRegisterUserResponse = AuthCreateUserSessionResponse;

export enum NativeLoginTypes {
  Pin = 'pin',
  Biometric = 'Biometric',
}

export interface AuthRefreshTokenRequest {
  longTermToken: string;
  userId?: number;
  loginType?: NativeLoginTypes;
}

export interface AuthShortTermRefreshResponse {
  shortTermToken: string;
  shortTermTokenExpiresInSeconds?: number;
}

export interface AuthLongTermRefreshResponse {
  longTermToken: string;
  longTermTokenExpiresInSeconds?: number;
}

export interface AuthUserData {
  fullName: string;
  userId: number;
  roles: string;
  ipAddress: string;
  userAgent: string;
}
export interface AuthUserResponse {
  phoneNumber: string;
  phoneNumberConfirmed: boolean;
  twoFactorEnabled: boolean;
  userName: string;
  middleNames: string;
  gender: string;
  signUpDateNzt: string;
  dateOfBirthNzt: string;
  idType: number;
  licenceNumber: string;
  licenceVersion: string;
  passportNumber: string;
  passportExp: string;
  occupation: number;
  employmentType: number;
  employer: string;
  timeAtEmployer: string;
  agreedToTerms: boolean;
  director: boolean;
  shareholder: boolean;
  seniorManager: boolean;
  employee: boolean;
  relatedParty: boolean;
  cantBorrow: boolean;
  hasWorkVisa: boolean;
  overseas: boolean;
  address: string;
  addressUnit: string;
  addressNo: string;
  addressStreet: string;
  addressCity: string;
  addressSuburb: string;
  addressPostCode: string;
  addressCountry: string;
  rolesList: string;
  userData: AuthUserData;
  usCitizen: boolean;
  mainCountryOfTaxResidency: string;
  socialSecurityNumber: string;
  thirdPartyId: number;
  thirdPartyName: string;
  declinedLoanInsideBounds: boolean;
  employmentDisplay: string;
  active: boolean;
  isInvestor: boolean;
  isBorrower: boolean;
  isVerified: boolean;
  isTemporaryPassword: boolean;
  verificationInProgress: boolean;
  emailConfirmed: boolean;
  id: number;
  fullName: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface AuthDeleteUserAccountRequest {
  active: boolean;
  isInvestor: boolean;
  isBorrower: boolean;
  isVerified: boolean;
  verificationInProgress: boolean;
  emailConfirmed: boolean;
  id: number;
  fullName: string;
  email: string;
  firstName: string;
  lastName: string;
}

// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
// Request.  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

export interface AuthUpdateShortTermTokenRequest {
  longTermToken: string;
}

export interface AuthCreateUserSessionRequest {
  username: string;
  password: string;
}

export interface AuthDeleteUserSessionRequest {
  longTermToken: string;
}

export interface AuthConfirmEmailRequest {
  userId: number;
  code: string;
}

export interface AuthForgotPasswordRequest {
  username: string;
}

export interface AuthChangePasswordRequest {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}
export interface AuthResetPasswordRequest {
  userId: number;
  code: string;
  newPassword: string;
  confirmPassword: string;
}

export interface AuthHasPasswordRequest {
  userId: number;
  code: string;
}
export interface AuthHasPasswordResponse {
  userHasPassword: boolean;
  userId: number;
}

export interface AuthSetInitialPasswordRequest {
  userId: number;
  code: string;
  newPassword: string;
  confirmPassword: string;
}

export interface AuthSetInitialPasswordResponse {
  shortTermToken: string;
  shortTermTokenExpiresInSeconds: number;
  longTermToken: string;
  userId: number;
}

export interface AuthRegisterUserGenericRequest {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  middleNames: string;
  lastName: string;
  dateOfBirthNzt: string;
  phoneNumber: string;
  hearAboutUs: string;
  hearAboutUsOther: string;
  usCitizen: boolean;
  mainCountryOfTaxResidency: string;
  socialSecurityNumber: string;
  gender: string;
  termsAndConditions: boolean;
  acceptInvestorTerms: boolean;
  nzResidentOrCitizen: boolean;
  ipAddress: string;
  userAgent: string;
  platform: string;
  browser: string;
  inviteId: string;
  referralUrl: string;
  referrerId: number;
  productId: number;
  promoCode: string;
  optInSmsMarketing: boolean;
  optInEmailMarketing: boolean;
}

export interface AuthRegisterUserRequest extends AuthRegisterUserGenericRequest, SquirrelAddress {
  clientId: string;
}

export enum PushServerIdEnum {
  Legacy = 1,
  Hybrid = 2,
}

export interface registerDeviceRequest {
  pushDeviceToken: string;
}

// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
// Misc. * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

export interface AuthState {
  isLoading: boolean;
  isError: boolean;
  user: AuthUserResponse;
  userLoading: boolean;
  userLoaded: boolean;
  userVerify: IUserVerifyV2;
  userVerifyLoading: boolean;
  userVerifyLoaded: boolean;
}

export interface CreateGenericRequest {
  actions$: any; // Observable<any>;
  makeRequest: (payload: any) => any; // Observable<any>;
  successMessage?: string;
  errorMessage?: string;
  startAction: Action;
  successAction: Action;
  failAction: Action;
  useMergeMap?: boolean;
  passThroughPayloadOnSuccess?: boolean;
}

export enum LocalStorage {
  longTermToken = 'TOKEN/LONG',
  shortTermToken = 'TOKEN/SHORT',
  userId = 'ID/USER',
  customerFormAuthToken = 'FORM_TOKEN/AUTH',
}

export enum PreferenceStorage {
  ReturningUser = 'Returning',
  IntroBiometric = 'INTRO_BIOMETRIC',
  IntroMFA = 'INTRO_MFA',
  IntroPin = 'INTRO_PIN',
  PendingNotification = 'PENDING_NOTIFICATION',
  InvestmentsCardType = 'INVESTMENTS_CARD_TYPE',
}

export enum ErrorKeys {
  invalidCredentials = 'InvalidCredentials',
  expiredVerifyToken = 'ExpiredVerifyToken',
  invalidToken = 'Invalid token',
}

// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
// Template. * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
export interface AuthRegisterUserItemTemplate {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  middleNames: string;
  lastName: string;
  dateOfBirthNzt: { day: string; month: string; year: string };
  phoneNumber: string;
  hearAboutUs: string;
  hearAboutUsOther: string;
  usCitizen: boolean;
  mainCountryOfTaxResidency: string;
  socialSecurityNumber: string;
  gender: string;
  termsAndConditions: boolean;
  acceptInvestorTerms: boolean;
  nzResidentOrCitizen: boolean;
  ipAddress: string;
  userAgent: string;
  platform: string;
  browser: string;
  inviteId: string;
  referralUrl: string;
  referrerId: number;
  productId: number;
  promoCode: string;
  optInSmsMarketing: boolean;
  optInEmailMarketing: boolean;
  address: Address;
}
