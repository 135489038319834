import { NotificationTypes } from '@views/misc/enums/enums.helpers';

import { NotificationActions } from './notifications.types';

// This is the amount of results that will load intially and on each "load more" request
export const NOTIFICATIONS_LOAD_COUNT = 10;

export function returnNotificationRoute({
  notificationType,
  notificationAction,
  entityId,
  investmentId,
  investmentOrderId,
  investmentOfferId,
}: {
  notificationType?: NotificationTypes;
  notificationAction?: NotificationActions;
  entityId?: string | number;
  investmentId?: string | number;
  investmentOrderId?: string | number;
  investmentOfferId?: string | number;
}): (string | number)[] {
  if (
    (notificationType == NotificationTypes.Repayment ||
      notificationType == NotificationTypes.Deposit ||
      notificationAction == NotificationActions.ViewBankAccountTransactions) &&
    entityId !== undefined
  ) {
    return ['investor', 'on-call-accounts', 'entities', entityId];
  }
  if (
    (notificationType == NotificationTypes.InvestmentStart ||
      notificationType == NotificationTypes.InvestmentSold ||
      notificationAction == NotificationActions.ViewInvestmentSummary) &&
    entityId !== undefined &&
    investmentId !== undefined
  ) {
    return ['investor', 'entities', entityId, 'investments', 'statement', investmentId];
  }
  if (
    (notificationType == NotificationTypes.InvestmentOffer ||
      notificationAction == NotificationActions.ViewInvestmentOffer) &&
    entityId !== undefined &&
    investmentOrderId !== undefined &&
    investmentOfferId !== undefined
  ) {
    return ['investor', 'entities', entityId, 'order', investmentOrderId, 'confirm-offer', investmentOfferId];
  }
  if (notificationAction == NotificationActions.ViewInvestmentOrders && entityId !== undefined) {
    return ['investor', 'investment-orders', 'entities', entityId];
  }
  return undefined;
}
