import { createFeatureSelector, createSelector } from '@ngrx/store';

import { formatBankAccountUtility } from '@core/utilities/format/account/format-account.utility';
import { formatCurrencyUtility } from '@core/utilities/format/currency/format-currency.utility';

import { ConstantsState } from './constants.types';

export const selectConstants = createFeatureSelector<ConstantsState>('constants');

export const selectConstantItems = createSelector(selectConstants, constants => constants?.items);

export const selectConstantCountries = createSelector(selectConstantItems, constantItems => constantItems?.countries);

export const selectConstantPhoneNumber = createSelector(
  selectConstantItems,
  constantItems => constantItems?.phoneNumber,
);

export const selectConstantSquirrelIRDNumber = createSelector(
  selectConstantItems,
  constantItems => constantItems?.squirrelIRDNumber,
);

export const selectConstantSquirrelEmail = createSelector(
  selectConstantItems,
  constantItems => constantItems?.squirrelEmail,
);

export const selectConstantUrls = createSelector(selectConstantItems, ({ feedbackSurveyUrl }) => ({
  feedbackSurveyUrl,
}));

export const selectSurveyUrl = createSelector(selectConstantUrls, urls => urls?.feedbackSurveyUrl);

export const selectConstantBankDetails = createSelector(
  selectConstantItems,
  ({ minInvestmentAmount, squirrelMoneyAccountName, squirrelMoneyInvestorBankAccount }) => ({
    minInvestmentAmount: formatCurrencyUtility(minInvestmentAmount),
    squirrelMoneyAccountName,
    squirrelMoneyInvestorBankAccount: formatBankAccountUtility(squirrelMoneyInvestorBankAccount),
  }),
);

export const selectBorrowerBankAccount = createSelector(selectConstants, constants =>
  formatBankAccountUtility(constants?.borrower?.squirrelMoneyBorrowerBankAccount),
);
