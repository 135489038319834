import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';

import { NotificationItemTemplate } from './notifications.types';

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [MatButton],
  template: `
    <div
      class="notification"
      [class.read]="notification?.isRead"
      [class.next-notification-not-read]="nextNotificationNotRead"
      data-testid="notification-item"
    >
      <p class="mat-subtitle-2 title">{{ notification?.notificationTypeDisplay }}</p>
      <p class="mat-caption date">{{ notification?.createDateNztDisplay }}</p>
      <p>{{ notification?.message }}</p>
      <button mat-stroked-button color="primary" (click)="view()">View</button>
    </div>
  `,
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
  @Input() notification: NotificationItemTemplate;
  // This is so we know not to show a divider
  @Input() nextNotificationNotRead: boolean;
  @Output() notificationEvent = new EventEmitter<NotificationComponentOutput>();

  view(): void {
    this.notificationEvent.emit({
      action: 'view',
      notification: this.notification,
    });
  }
}

export interface NotificationComponentOutput {
  action: 'view';
  notification: NotificationItemTemplate;
}
