import { Component, Inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { ThemePalette } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  imports: [MatButton, MatIcon, MatSnackBarModule],
  standalone: true,
  template: `
    @if (data?.icon) {
      <mat-icon
        [color]="data?.color !== 'success' ? data?.color : undefined"
        fontSet="fa-solid"
        [fontIcon]="data?.icon"
        class="icon"
        [class.success]="data?.color === 'success'"
      />
    }

    <div class="message-wrapper">
      @if (data?.title) {
        <p class="title">{{ data?.title }}</p>
      }
      @if (data?.message) {
        <p class="message">{{ data?.message }}</p>
      }
    </div>

    @if (data?.buttonMessage) {
      <button
        mat-button
        [color]="data?.color !== 'success' ? data?.color : undefined"
        (click)="clickAction()"
        class="button"
        [attr.data-testId]="data?.testId"
        [class.success]="data?.color === 'success'"
      >
        {{ data?.buttonMessage }}
      </button>
    }
  `,
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      icon?: string;
      color?: ThemePalette | 'success';
      title?: string;
      message?: string;
      buttonMessage?: string;
      testId?: string;
    },
    public snackBarRef: MatSnackBarRef<SnackbarComponent>,
  ) {}

  clickAction(): void {
    this.snackBarRef.dismissWithAction();
  }
}
