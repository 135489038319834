import { environment } from '@environments/environment';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { PreloadAllModules, Route, RouterModule } from '@angular/router';

import { CustomerFormsService } from '@views/customer-forms/customer-forms.service';
import { CustomerFormsStore } from '@views/customer-forms/customer-forms.store';
import { CREATE_ENTITY_FLOW_ROUTE } from '@views/investor/investor.helpers';
import { AuthGuard } from '@views/misc/auth/auth.guard';
import { EnumResolver } from '@views/misc/enums/enums.resolver';

import { LinkResolver } from '@core/utilities/link/link-resolver.service';
import { NumberParamGuardResolver } from '@core/utilities/routes/number-param-guard-resolver.service';

import { CustomerFormInterceptor } from './views/customer-forms/customer-forms.interceptor';

/** Routes for the main entry point for this Squirrel application. */
const routes: Route[] = [
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // Login.  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  {
    path: 'login',
    data: { title: '' },
    loadChildren: () => import('@views/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'register',
    data: { title: 'Join Squirrel in less than 5 minutes' },
    loadChildren: () => import('@views/login/register/register.module').then(m => m.LoginRegisterModule),
  },
  {
    path: 'forgot-password',
    data: { title: 'Forgot password' },
    loadChildren: () => import('@views/login/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'reset-password/:userId',
    data: { title: 'Reset password' },
    resolve: {
      validParams: NumberParamGuardResolver,
    },
    loadChildren: () => import('@views/login/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
  },
  {
    path: 'account/initiallogin',
    data: { title: 'Set password' },
    loadChildren: () => import('@views/login/initial-login/initial-login.module').then(m => m.InitialLoginModule),
  },
  {
    path: 'account/confirmemail',
    data: { title: 'Verify email' },
    loadChildren: () => import('@views/login/confirm-email/confirm-email.module').then(m => m.ConfirmEmailModule),
  },

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // Customer forms. * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  {
    path: 'customer-forms',
    providers: [
      CustomerFormsStore,
      CustomerFormsService,
      provideHttpClient(withInterceptorsFromDi()),
      { provide: HTTP_INTERCEPTORS, useClass: CustomerFormInterceptor, multi: true },
    ],
    loadComponent: () => import('@views/customer-forms/customer-forms.component').then(x => x.CustomerFormsComponent),
    loadChildren: () => import('./views/customer-forms/routes').then(mod => mod.CUSTOMER_FORM_ROUTES),
  },

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // Gated Content.  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // ⚠️ Users must be authenticated to access! * * * * * * * * * * * * * * * * *
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

  {
    path: '',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('@views/authorized-views/authorized-views.component').then(x => x.AuthorizedViewsComponent),
    children: [
      /** Settings: */
      {
        path: 'settings',
        pathMatch: 'prefix',
        data: { title: 'Settings' },
        canActivate: [AuthGuard],
        resolve: { enums: EnumResolver },
        loadChildren: () => import('@views/settings/settings.module').then(m => m.SettingsModule),
      },

      /** Borrower: */
      {
        path: 'borrower',
        canActivate: [AuthGuard],
        resolve: { enums: EnumResolver },
        loadChildren: () => import('@views/borrower/borrower.module').then(m => m.BorrowerModule),
      },

      /** Investor: */
      {
        path: 'investor',
        canActivate: [AuthGuard],
        resolve: { enums: EnumResolver },
        loadChildren: () => import('@views/investor/investor.module').then(m => m.InvestorModule),
      },

      /** Profile: */
      {
        path: 'profile',
        data: { title: 'Profile' },
        canActivate: [AuthGuard],
        resolve: { enums: EnumResolver },
        children: [
          {
            path: 'incomplete',
            loadComponent: () =>
              import('@views/profile/profile-incomplete.component').then(c => c.ProfileIncompleteComponent),
          },
          {
            path: 'validate-id',
            loadChildren: () => import('@views/profile/validate-id/validate-id.module').then(m => m.ValidateIdModule),
          },
        ],
      },
      // Alternate version for profile pages used when part of the create entity process
      {
        path: CREATE_ENTITY_FLOW_ROUTE,
        // createEntity flag to show that user is in create entity process
        data: { title: 'Create entity', createEntity: true },
        children: [
          {
            path: 'incomplete',
            loadComponent: () =>
              import('@views/profile/profile-incomplete.component').then(c => c.ProfileIncompleteComponent),
          },
          {
            path: 'validate-id',
            loadChildren: () => import('@views/profile/validate-id/validate-id.module').then(m => m.ValidateIdModule),
          },
        ],
      },

      {
        path: '',
        pathMatch: 'full',
        data: { title: 'Dashboard' },
        canActivate: [AuthGuard],
        resolve: { enums: EnumResolver },
        loadChildren: () => import('@views/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
    ],
  },

  {
    path: 'link',
    resolve: { invalidLink: LinkResolver },
    loadChildren: () => import('@views/misc/scenario/invalid-link/invalid-link.module').then(m => m.InvalidLinkModule),
  },

  /** 404: */
  {
    path: '**',
    redirectTo: '',
  },
];

/**
 * `import` dependancies for `AppRoutingModule`.
 */
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      enableTracing: environment.logRoutes,
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
