import { AuthRegisterUserItemTemplate } from '@views/misc/auth/auth.types';

import { convertAddressIntoRequestData } from '@core/utilities/address';
import { convertDobIntoRequestData } from '@core/utilities/format/date/format-date.utility';

export const convertRegisterTemplateIntoRequestData = (formData: AuthRegisterUserItemTemplate) => {
  const { address, dateOfBirthNzt, ...remainingItems } = formData;

  return {
    ...remainingItems,
    ...convertAddressIntoRequestData(address),
    dateOfBirthNzt: convertDobIntoRequestData({
      day: dateOfBirthNzt.day,
      month: dateOfBirthNzt.month,
      year: dateOfBirthNzt.year,
    }),
  };
};
