import { EnumItems, FetchEnumsResponse } from './enums.types';

/**
 * Enum global look up value by key
 */
export enum EnumLoanStatusIds {
  Active = 19,
  AutoDeclined = 6,
  Declined = 9,
  WriteOff = 21,
  Closed = 20,
  PreApproved = 23,
  None = 0,
  Relationship = 1,
  BankAccount = 27,
  AwaitingCreditManager = 5,
  AwaitingDocumentSignOff = 13,
  AwaitingDocumentGeneration = 15,
  AwaitingDocumentSignOffExpired = 14,
  AwaitingDrawdown = 16,
  Completed = 17,
  OnHold = 18,
  AwaitingFundingApprovalExpired = 11,
  AwaitingUserVerification = 2,
}

export enum LoanProducts {
  Computer = 1,
  DebtConsolidation = 2,
  EducationExpenses = 3,
  HolidayExpenses = 4,
  HomeImprovements = 5,
  HouseholdItems = 6,
  LegalFees = 7,
  MedicalExpenses = 8,
  PurchaseBoat = 9,
  PurchaseMotorVehicle = 10,
  WeddingExpenses = 11,
  PurchaseProperty = 12,
  Other = 13,
  ThirdParty = 14,
  MaternityExpenses = 15,
  HomeOwners = 16,
  MotorBike = 17,
  Float = 18,
  TinyHouse = 19,
  Trailer = 20,
  HomeOwnerRenovation = 21,
  HomeOwnerLandscaping = 22,
  HomeOwnerPool = 23,
  HomeOwnerOther = 24,
  CarRefinancing = 25,
  Mortgage = 27,
  LaunchPad = 28,
}

export enum loanFileTypes {
  PaySlip = 1,
  BankStatement = 2,
  Rates = 3,
  MortgageStatement = 4,
  SupportingDocument = 5,
}

export enum NotificationTypes {
  Repayment = 1,
  Deposit = 2,
  InvestmentStart = 3,
  Admin = 4,
  InvestmentSold = 5,
  InvestmentOffer = 6,
}

export enum EnumAutoSellOption {
  undecided = 1,
  keep = 2,
  sell = 3,
}

export enum TransactionTypes {
  RateChange = 0,
  Repayment = 2,
  Interest = 3,
  Deposit = 10,
  BankAccountInterest = 25,
  EarlyRepayment = 27,
  BankAccountInterestResidentsWithholdingTax = 51,
  BankAccountInterestResidentsWithholdingTaxDishonour = 52,
  // This is not the complete enums list refer to the borrowers enum api to get full list
}

export enum ChartOfAccountIds {
  salary = 12,
  businessIncome = 15,
  rent_BoarderIncome = 16,
  other = 28,
  partnerIncome = 36,
}

export enum TaxTypeIds {
  beforeTax = 1,
  afterTax = 2,
}

export enum WithholdingTaxTypeId {
  ten = 7,
  tenPointFive = 2,
  seventeenPointFive = 3,
  twentyEight = 6,
  thirty = 4,
  thirtyThree = 5,
  thirtyNine = 8,
  none = 1,
}

export enum EntityTypeId {
  charity = 4,
  company = 2,
  financialInstitution = 5,
  ltc = 6,
  person = 1,
  trust = 3,
}

export enum RepaymentTypeId {
  interestOnly = 1,
  principalAndInterest = 2,
  interestCapitalised = 3,
}

/**
 * Finds the corresponding text representation of a number reference inside the
 * ENUM's data set.
 *
 * @example
 * convertEnumValueToTextFromKey({ ... }, "paymentFrequencyId", 2);
 * // returns "Fortnightly"
 *
 * @note this should ONLY be called inside of sections that have already fetched
 * the ENUM data as part of our route resolver sequence.
 */
export const convertEnumValueToTextFromKey = (enumItems: FetchEnumsResponse, key: string, value: number): string => {
  if (!(enumItems && key && value)) {
    return '';
  }

  const section: EnumItems = enumItems[key];
  const keyValuePairs = Object.values(section);
  const keyValuePair = keyValuePairs.find(item => item.value === value);
  const text = keyValuePair.text;

  return text || '';
};

/**
 * Finds the corresponding value of a key inside the
 * ENUM's data set.
 *
 * @example
 * convertEnumKeyTovalue({ ... }, "loanStatusId", "active");
 * // returns 19
 *
 * @note this should ONLY be called inside of sections that have already fetched
 * the ENUM data as part of our route resolver sequence.
 */
export const convertEnumKeyToValue = (
  enumItems: FetchEnumsResponse,
  parentKey: string,
  childKey: string,
): string | number => {
  if (!(enumItems && parentKey && childKey)) {
    return '';
  }

  const value: number = enumItems[parentKey][childKey].value;

  return value;
};
