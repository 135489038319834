import { createAction, props } from '@ngrx/store';

import { MarketplaceFetchResponse, ResponseBrochureStatistics, ResponseStatistics } from './statistics.types';

export const statisticsBrochureFetchListStart = createAction('STATISTICS/BROCHURE_FETCH_LIST_START');
export const statisticsBrochureFetchListSuccess = createAction(
  'STATISTICS/BROCHURE_FETCH_LIST_SUCCESS',
  props<{ payload: ResponseBrochureStatistics }>(),
);
export const statisticsBrochureFetchListFail = createAction('STATISTICS/BROCHURE_FETCH_LIST_FAIL');
export const statisticsFetchListStart = createAction('STATISTICS/STATISTICS_FETCH_LIST_START');
export const statisticsFetchListSuccess = createAction(
  'STATISTICS/STATISTICS_FETCH_LIST_SUCCESS',
  props<{ payload: ResponseStatistics }>(),
);
export const statisticsFetchListFail = createAction('STATISTICS/STATISTICS_FETCH_LIST_FAIL');
export const statisticsMarketplaceCustomFetchStart = createAction('STATISTICS/MARKETPLACE_CUSTOM_FETCH_START');
export const statisticsMarketplaceCustomFetchSuccess = createAction(
  'STATISTICS/MARKETPLACE_CUSTOM_FETCH_SUCCESS',
  props<{ payload: MarketplaceFetchResponse }>(),
);
export const statisticsMarketplaceCustomFetchFail = createAction('STATISTICS/MARKETPLACE_CUSTOM_FETCH_FAIL');
