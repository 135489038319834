import { createAction, props } from '@ngrx/store';

import { ProfileItemResponse, UpdateProfileRequest } from './profile.types';

export const profileFetchItemStart = createAction('PROFILE/FETCH_ITEM_START');
export const profileFetchItemSuccess = createAction('PROFILE/FETCH_ITEM_SUCCESS', props<{ payload }>());
export const profileFetchItemFail = createAction('PROFILE/FETCH_ITEM_FAIL');

export const profileUpdateItemStart = createAction(
  'PROFILE/UPDATE_ITEM_START',
  props<{ payload: UpdateProfileRequest }>(),
);
export const profileUpdateItemSuccess = createAction(
  'PROFILE/UPDATE_ITEM_SUCCESS',
  props<{ payload: ProfileItemResponse }>(),
);
export const profileUpdateItemFail = createAction('PROFILE/UPDATE_ITEM_FAIL');

export const profileResetItem = createAction('PROFILE/RESET_ITEM');
