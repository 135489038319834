import { changeUser, getUser, initialize, logCustomEvent, openSession } from '@braze/web-sdk';

import { environment } from '@environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Capacitor } from '@capacitor/core';

import { take } from 'rxjs';

import { AnalyticsService } from './analytics.service';

export enum BrazeEventEnum {
  ApplicationCreate = 'sdk_mortgages_loan_application_created',
  IncomeStarted = 'sdk_mortgages_loan_application_income_started',
  ExpensesStarted = 'sdk_mortgages_loan_application_expenses_started',
  DepositStarted = 'sdk_mortgages_loan_application_deposit_started',
  FinancialAssetsStarted = 'sdk_mortgages_loan_application_financial_assets_started',
  PhysicalAssetsStarted = 'sdk_mortgages_loan_application_physical_assets_started',
  DebtsStarted = 'sdk_mortgages_loan_application_debts_started',
  Complete = 'sdk_mortgages_loan_application_complete',
  DocCollectionStarted = 'sdk_mortgages_doc_collection_started',
  DocCollectionComplete = 'sdk_mortgages_doc_collection_complete',
  LoggedIn = 'sdk_account_login',
  InvestorRegisterLoaded = 'sdk_account_investor_register_loaded',
  InvestorRegistered = 'sdk_account_investor_registered',
  InvestorMissingProfileComplete = 'sdk_account_investor_missing_profile_complete',
  InvestorSecureMeComplete = 'sdk_account_investor_secure_me_complete',
  InvestorInvestmentAccountValidated = 'sdk_account_investor_investment_account_validated',
  InvestorNandPComplete = 'sdk_account_investor_nature_and_purpose_complete',
  InvestorNandPSummaryLoaded = 'sdk_account_investor_nature_and_purpose_summary_loaded',
  InvestorAccountInvestmentAccountCreated = 'sdk_account_investor_investment_account_created',
  InvestorAccountInvestmentAccountGoToDeposit = 'sdk_account_investor_investment_account_go_to_deposit',
  InvestorAccountInvestmentAccountGoToDashboard = 'sdk_account_investor_investment_account_go_to_dashboard',
  InvestorOrderPlaced = 'sdk_account_investor_order_placed',
  InvestorAccountOpended = 'sdk_account_investor_account_opened',
  InvestorAccountDocsOpened = 'sdk_account_investor_docs_opened',
  BorrowerMoneyLoanOpened = 'sdk_account_borrower_money_loan_opened',
  BorrowerMoneyLoanDocsOpened = 'sdk_account_borrower_money_loan_docs_opened',
  BorrowerMortgageAppOpened = 'sdk_account_borrower_mortgage_app_opened',
  BorrowerMortgageAppDocsOpened = 'sdk_account_borrower_mortgage_app_docs_opened',
  BorrowerMortgageDocsNotified = 'sdk_account_borrower_mortgage_app_docs_notified',
}

export enum BrazeEventPropertiesEnum {
  ApplicationId = 'application_id',
  LoanAmount = 'loan_amount',
  LoanTerm = 'loan_term',
  InvestmentOrderType = 'investment_order_type',
  UserId = 'user_id',
  InvestorEntityId = 'investor_entity_id',
  Platform = 'platform',
}

export enum BrazeEventInvestmentOrderTypeEnum {
  OneOff = 'OneOff',
  AutoReinvest = 'AutoReinvest',
}

@Injectable({
  providedIn: 'root',
})
export class BrazeService {
  constructor(
    private analyticsService: AnalyticsService,
    private http: HttpClient,
  ) {}

  init(): void {
    if (environment.brazeAPIKey.length) {
      // initialize the SDK
      initialize(environment.brazeAPIKey, {
        baseUrl: environment.brazeSDKEndpoint,
      });

      // Disable in app messages for native apps
      // // optionally show all in-app messages without custom handling
      // automaticallyShowInAppMessages();

      // Be sure to call `openSession` after `automaticallyShowInAppMessages`
      openSession();
    }
  }

  setCustomEvent(p: {
    eventName: BrazeEventEnum;
    eventProperties?: { [key: string]: string | number };
    applicationId?: string;
    userId?: string;
    investorEntityId?: string;
    InvestmentOrderType?: BrazeEventInvestmentOrderTypeEnum;
  }): void {
    if (environment.brazeAPIKey.length) {
      let eventProperties: { [key: string]: string | number } = {};

      if (p.eventProperties !== undefined) {
        eventProperties = { ...p.eventProperties };
      }
      if (p.applicationId !== undefined) {
        eventProperties[BrazeEventPropertiesEnum.ApplicationId] = p.applicationId;
      }
      if (p.userId !== undefined) {
        eventProperties[BrazeEventPropertiesEnum.UserId] = p.userId;
      }
      if (p.investorEntityId !== undefined) {
        eventProperties[BrazeEventPropertiesEnum.InvestorEntityId] = p.investorEntityId;
      }
      if (p.InvestmentOrderType !== undefined) {
        eventProperties[BrazeEventPropertiesEnum.InvestmentOrderType] = p.InvestmentOrderType;
      }

      eventProperties[BrazeEventPropertiesEnum.Platform] = Capacitor.getPlatform();

      logCustomEvent(p.eventName, eventProperties);
      // Also log events to analytics
      this.analyticsService.event(p.eventName, eventProperties);
    }
  }

  getAndChangeUser(email: string): void {
    if (environment.brazeUserLookupEndpoint.length) {
      // Check to see if we have a userID
      this.http
        .get<string>(environment.brazeUserLookupEndpoint, {
          params: { email, 'subscription-key': environment.brazeUserLookupBearer },
          headers: { 'Content-Type': 'text/plain; charset=utf-8;' },
          responseType: 'text' as 'json',
        })
        .pipe(take(1))
        .subscribe(userID => {
          if (environment.brazeAPIKey.length) {
            if (userID !== undefined) {
              // if a valid userID is returned then change the user
              changeUser(userID.toString());
            }
          }
        });
    }
  }

  setCustomUserAttribute(key: string, value: string | number | boolean | Date | string[]): void {
    if (environment.brazeAPIKey.length) {
      getUser().setCustomUserAttribute(key, value);
    }
  }
}
