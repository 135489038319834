import { NgxCurrencyInputMode, provideEnvironmentNgxCurrency } from 'ngx-currency';

import { environment } from '@environments/environment';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconRegistry } from '@angular/material/icon';
import { MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { InvestorSatisticsModule } from '@views/investor/statistics/statistics.module';
import { AuthService } from '@views/misc/auth/auth.service';
import { NativeAuthService } from '@views/misc/auth/native-auth.service';
import { StorageService } from '@views/misc/auth/storage.service';

import { PrivacyScreenComponent } from '@components/privacy-screen/privacy-screen.component';
import { SnackBarService } from '@components/snackbar/snackbar.service';

import { MatDayjsDateModule } from '@core/material-dayjs-adapter';
import { AnalyticsService } from '@core/utilities/analytics.service';
import { BrazeService } from '@core/utilities/braze.service';
import { LoggerModule } from '@core/utilities/logger/logger.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpErrorInterceptor } from './core/utilities/networkLayer/interceptor.http';
import * as redux from './reducers';

export const MAT_DAYJS_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const customNgxCurrencyConfig = {
  align: 'left',
  allowNegative: false,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '',
  suffix: '',
  thousands: ',',
  nullable: true,
  min: null,
  max: null,
  inputMode: NgxCurrencyInputMode.Natural,
};

/** The main entry point for this Squirrel application. */
@NgModule({
  declarations: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    Title,
    AnalyticsService,
    BrazeService,
    AuthService,
    NativeAuthService,
    SnackBarService,
    StorageService,
    { provide: MAT_DATE_FORMATS, useValue: MAT_DAYJS_DATE_FORMATS },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill', subscriptSizing: 'dynamic', hideRequiredMarker: true },
    },
    {
      provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
    provideEnvironmentNgxCurrency(customNgxCurrencyConfig),
    // needed for global use
    {
      provide: MatDialogRef,
      useValue: {},
    },
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LoggerModule,
    StoreModule.forRoot(redux.reducers, {
      metaReducers: redux.metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot(redux.effects),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      connectInZone: true,
    }),
    BrowserAnimationsModule,
    MatDayjsDateModule,
    PrivacyScreenComponent,
    // needed for global use
    MatSnackBarModule,
    MatDialogModule,
    InvestorSatisticsModule,
  ],
})
export class AppModule {
  constructor(public matIconRegistry: MatIconRegistry) {
    matIconRegistry.registerFontClassAlias('fa-light', 'fa-light');
    matIconRegistry.registerFontClassAlias('fa-solid', 'fa-solid');
  }
}
