import { NotificationTypes } from '@views/misc/enums/enums.helpers';

// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
// Response. * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

export interface NotificationItemResponse {
  notificationId: number;
  amount: number;
  createDateNzt: string;
  readDateNzt: string;
  isRead: boolean;
  title: string;
  message: string;
  entityId: number;
  investmentId: number;
  bankAccountId: number;
  investmentOrderId: number;
  investmentOfferId: number;
  notificationTypeId: NotificationTypes;
  notificationTypeDisplay: string;
  userId: number;
  pushNotificationId: number;
}

export interface NotificationMetaDataResponse {
  pageIndex: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
}

export interface NotificationsResponse {
  metadata: NotificationMetaDataResponse;
  items: NotificationItemResponse[];
}

export type UpdateItemResponse = NotificationItemResponse;

export interface NotificationsUnreadCountResponse {
  count: number;
}

// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
// Request. * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

export interface SelectNotificationsRequest {
  pageIndex: number;
  pageSize: number;
}

export interface UpdateNotificationRequest {
  notificationId: number;
}

export interface UpdateAllRequest {
  userId: number;
  entities: NotificationItemResponse[];
}

// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
// Misc. * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

export interface NotificationsState {
  notificationsResponse: NotificationsResponse;
  loadCount: number;
  unreadCount: number;
  isLoaded: boolean;
  isLoading: boolean;
  isError: boolean;
}

// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
// Template. * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

export interface NotificationItemTemplate extends NotificationItemResponse {
  createDateNztDisplay: string;
}

export enum NotificationActions {
  ViewBankAccountTransactions = 'ViewBankAccountTransactions',
  ViewNotifications = 'ViewNotifications',
  ViewInvestmentSummary = 'ViewInvestmentSummary',
  ViewInvestmentOffer = 'ViewInvestmentOffer',
  ViewInvestmentOrders = 'ViewInvestmentOrders',
}

export interface NotificationEventData {
  MarkAsRead: 'True' | 'False';
  Action: NotificationActions;
  EntityId: string;
  UserId: string;
  NotificationId: string;
  BankAccountId?: string;
  InvestmentId?: string;
  InvestmentOfferId?: string; // Only applied to NotificationActions.ViewInvestmentOffer
  InvestmentOrderId?: string; // Only applied to NotificationActions.ViewInvestmentOffer
  aps: { alert: { body: string; tittle: string } };
}
