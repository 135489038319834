import { environment } from '@environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthService } from '@views/misc/auth/auth.service';

import {
  SelectSquirrelEntitiesResponse,
  SquirrelEntityItemResponse,
  UpdateSquirrelEntityRequest,
  UpdateSquirrelEntityResponse,
} from './entities.types';

@Injectable({
  providedIn: 'root',
})
export class SquirrelEntitiesService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  fetchList(): Observable<SelectSquirrelEntitiesResponse> {
    return this.http.get<SelectSquirrelEntitiesResponse>(`${environment.endpointApiOne}/v2/investor-entities`, {
      headers: this.authService.createAuthHeaders(),
      params: { pageIndex: 0, pageSize: 100 },
    });
  }

  fetchItem(payload: {
    // Not actually part of the BED payload but is sent through from the UI to
    // build the dynamic URL endpoint.
    investorEntityId: number;
  }): Observable<SquirrelEntityItemResponse> {
    const { investorEntityId } = payload;

    return this.http.get<SquirrelEntityItemResponse>(
      `${environment.endpointApiOne}/v2/investor-entities/${investorEntityId}`,
      {
        headers: this.authService.createAuthHeaders(),
      },
    );
  }

  updateItem(payload: UpdateSquirrelEntityRequest): Observable<UpdateSquirrelEntityResponse> {
    const { investorEntityId } = payload;

    return this.http.put<UpdateSquirrelEntityResponse>(
      `${environment.endpointApiOne}/v2/investor-entities/${investorEntityId}`,
      payload,
      {
        headers: this.authService.createAuthHeaders(),
      },
    );
  }
}
