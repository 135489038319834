import {
  AvailableResult,
  BiometryType,
  SquirrelCapacitorSecureStorage,
} from '@squirrel/capacitor-secure-storage-plugin';
import { SafeArea } from 'capacitor-plugin-safe-area';

import { environment } from '@environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';

import { Store } from '@ngrx/store';

import { take } from 'rxjs';

import { CapacitorPlatformTypes } from '@core/capacitor/capacitor.types';

import { authCreateUserSessionFromLongTermTokenStart, authRegisterFirebaseTokenStart } from './auth.actions';
import {
  AuthLongTermRefreshResponse,
  AuthRefreshTokenRequest,
  NativeLoginTypes,
  PreferenceStorage,
} from './auth.types';
import { StorageService } from './storage.service';

const SERVER = environment.accountUrl;

/**
 * The Authentication service that handles native app specfic authentication tasks
 */
@Injectable({
  providedIn: 'root',
})
export class NativeAuthService {
  nativeBiometricAvailable = false;
  nativeBiometricType: BiometryType;
  safeAreaTop = 0;
  safeAreaBottom = 0;

  constructor(
    private store: Store,
    private storageService: StorageService,
    private http: HttpClient,
  ) {
    // Set native and biometic flags
    if (Capacitor.isNativePlatform()) {
      // Only save Save user's credentials in native apps
      SquirrelCapacitorSecureStorage?.biometricIsAvailable().then((result: AvailableResult) => {
        this.nativeBiometricAvailable = result?.isAvailable;
        this.nativeBiometricType = result?.biometryType;
      });
      SafeArea.getStatusBarHeight().then(({ statusBarHeight }) => {
        // Remove 5px to acomodate for the padding thats already in the top nav
        const safeAreaTop = statusBarHeight - 5;
        // Don't allow height to be negative
        this.safeAreaTop = safeAreaTop > 0 ? safeAreaTop : 0;
      });

      SafeArea.getSafeAreaInsets().then(safeAreaInsets => {
        // Remove 5px to acomodate for the padding thats already in the top nav
        const safeAreaTop = safeAreaInsets?.insets?.top - 5;
        // Don't allow height to be negative
        this.safeAreaTop = safeAreaTop > 0 ? safeAreaTop : 0;

        if (Capacitor.getPlatform() === CapacitorPlatformTypes.Ios) {
          // only add footer nave padding for IOS the webview sits above the native nav in Android
          // Remove 12px to acomodate for the padding thats already in the nav
          const safeAreaBottom = safeAreaInsets?.insets?.bottom - 12;
          // Don't allow padding to be negative
          this.safeAreaBottom = safeAreaBottom > 0 ? safeAreaBottom : 0;
        }
      });

      Preferences.get({ key: PreferenceStorage.ReturningUser }).then(returningUser => {
        if (returningUser.value === null) {
          // If they are a new user delete all credentials that may stil be stored on device
          this.deleteAllCredentials();
        }
      });
    }
  }

  setPin(pin: string, callback: (successful: boolean) => void): void {
    if (Capacitor.isNativePlatform()) {
      // Only save Save user's credentials in native apps
      SquirrelCapacitorSecureStorage?.setPin({
        pin,
        username: this.storageService.getUserId(),
        password: this.storageService.getLongToken(),
        server: SERVER,
      })
        .then(() => {
          callback(true);
        })
        .catch(() => {
          callback(false);
        });
    } else {
      callback(false);
    }
  }

  setBiometricCredentials(callback: (successful: boolean) => void): void {
    if (Capacitor.isNativePlatform() && this.nativeBiometricAvailable) {
      // Only save Save user's credentials in native apps
      SquirrelCapacitorSecureStorage?.setBiometric({
        username: this.storageService.getUserId(),
        password: this.storageService.getLongToken(),
        server: SERVER,
      })
        .then(() => {
          callback(true);
        })
        .catch(() => {
          callback(false);
        });
    } else {
      callback(false);
    }
  }

  deleteAllCredentials(callback?: (successful: boolean) => void): void {
    // Delete user's credentials
    SquirrelCapacitorSecureStorage?.deleteAllCredentials({
      server: SERVER,
    })
      .then(() => {
        callback(true);
      })
      .catch(() => {
        callback(false);
      });
  }

  attemptPinLogin = (pinAttempt: string, callback?: (successful: boolean) => void): void => {
    if (Capacitor.isNativePlatform()) {
      SquirrelCapacitorSecureStorage?.verifyPin({
        pin: pinAttempt,
        server: SERVER,
      })
        .then(credentials => {
          // Payload to fresh long and short term tokens
          const payload = {
            userId: Number(credentials?.username),
            longTermToken: credentials?.password,
            loginType: NativeLoginTypes.Pin,
          };
          // Authentication successful login via userId and longTermToken
          this.store.dispatch(authCreateUserSessionFromLongTermTokenStart(payload));
          // Refresh the long term token then save it to the keychain to be used the next login attempt,
          // this means the user always has the maximum 90 days before expiry
          this.refreshLongTermToken(payload, refreshedLongTermToken => {
            if (refreshedLongTermToken !== undefined) {
              SquirrelCapacitorSecureStorage?.setPin({
                pin: pinAttempt,
                username: credentials?.username,
                password: refreshedLongTermToken,
                server: SERVER,
              });
            }
          });
          callback(true);
        })
        .catch(() => {
          callback(false);
          // Failed to authenticate
        });
    } else {
      callback(false);
    }
  };

  attemptBiometricLogin = (callback?: (successful: boolean) => void): void => {
    if (Capacitor.isNativePlatform() && this.nativeBiometricAvailable) {
      this.biometricLoginIsEnabled(isEnabled => {
        if (isEnabled) {
          // Only log the user in if they are returning, this means if a user deletes the app they will need to set up the biometric login again
          Preferences.get({ key: PreferenceStorage.ReturningUser }).then(returningUser => {
            if (returningUser.value !== null) {
              SquirrelCapacitorSecureStorage?.verifyBiometric({
                title: 'Log in',
                server: SERVER,
                returnCredentials: true,
              })
                .then(credentials => {
                  // Payload to fresh long and short term tokens
                  const payload = {
                    userId: Number(credentials?.username),
                    longTermToken: credentials?.password,
                    loginType: NativeLoginTypes.Biometric,
                  };
                  // Authentication successful login via userId and longTermToken
                  this.store.dispatch(authCreateUserSessionFromLongTermTokenStart(payload));
                  // Refresh the long term token then save it to the keychain to be used the next login attempt,
                  // this means the user always has the maximum 90 days before expiry
                  this.refreshLongTermToken(payload, refreshedLongTermToken => {
                    if (refreshedLongTermToken !== undefined) {
                      SquirrelCapacitorSecureStorage?.setBiometric({
                        username: credentials?.username,
                        password: refreshedLongTermToken,
                        server: SERVER,
                      });
                    }
                  });
                  callback(true);
                })
                .catch(() => {
                  callback(false);
                  // Failed to authenticate
                });
            }
          });
        }
      });
    }
  };

  biometricLoginIsEnabled = (callback: (biometricLoginIsEnabled: boolean) => void): void => {
    if (Capacitor.isNativePlatform()) {
      SquirrelCapacitorSecureStorage?.biometricIsSet({
        server: SERVER,
      })
        .then(() => {
          callback(true);
        })
        .catch(() => {
          callback(false);
        });
    } else {
      callback(false);
    }
  };

  pinLoginIsEnabled = (callback: (pinLoginIsEnabled: boolean) => void): void => {
    if (Capacitor.isNativePlatform()) {
      SquirrelCapacitorSecureStorage?.pinIsSet({
        server: SERVER,
      })
        .then(() => {
          callback(true);
        })
        .catch(() => {
          callback(false);
        });
    } else {
      callback(false);
    }
  };

  enableBiometricLogin = (callback?: (successful: boolean) => void): void => {
    if (Capacitor.isNativePlatform() && this.nativeBiometricAvailable) {
      // Authenticate using biometrics before logging the user in
      SquirrelCapacitorSecureStorage?.verifyBiometric({
        title: 'Enable biometric ID',
        server: SERVER,
      })
        .then(() => {
          this.setBiometricCredentials(successful => successful && callback(true));
        })
        .catch(() => {
          callback(false);
        });
    }
  };

  disableBiometricLogin = (callback?: (successful: boolean) => void): void => {
    if (Capacitor.isNativePlatform() && this.nativeBiometricAvailable) {
      SquirrelCapacitorSecureStorage?.removeBiometric({
        server: SERVER,
      })
        .then(() => {
          callback(true);
        })
        .catch(() => {
          callback(false);
        });
    }
  };

  disablePinLogin = (callback?: (successful: boolean) => void): void => {
    if (Capacitor.isNativePlatform()) {
      SquirrelCapacitorSecureStorage?.removePin({
        server: SERVER,
      })
        .then(() => {
          callback(true);
        })
        .catch(() => {
          callback(false);
        });
    }
  };

  linkFirebaseTokenToUser = (): void => {
    if (Capacitor.isNativePlatform()) {
      // Request notification permissions when a uselogs in
      FirebaseMessaging.requestPermissions().then(response => {
        if (response.receive === 'granted') {
          FirebaseMessaging.getToken({
            vapidKey: environment.firebase.vapidKey,
          }).then(({ token }) => {
            // Everytime we will re-register the token incase the token or user has changed
            this.store.dispatch(authRegisterFirebaseTokenStart({ payload: { pushDeviceToken: token } }));
          });
        }
      });
    }
  };

  /**
   * Returns new "Long Term" token from the current longterm token, that maybe saved externally like a keychain in the native apps
   */
  refreshLongTermToken = (
    payload: AuthRefreshTokenRequest,
    callback?: (refreshedLongTermToken?: string) => void,
  ): void => {
    const { longTermToken } = payload;

    this.http
      .post<AuthLongTermRefreshResponse>(
        `${environment.endpointApiTwo}/v2/login/refresh/long`,
        {
          longTermToken,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${longTermToken}`,
          },
        },
      )
      .pipe(take(1))
      .subscribe(
        response => {
          callback && callback(response?.longTermToken);
        },
        () => {
          callback && callback(undefined);
        },
      );
  };
}
