import { Address } from '@components/form/address/address.component';

// old legacy squirrel address format
export interface SquirrelAddress {
  address: string;
  addressUnit?: string;
  addressNo: string;
  addressStreet: string;
  addressCity: string;
  addressSuburb?: string;
  addressPostCode: string;
  addressCountry: string;
  addressMeshblock: number;
}

export const convertAddressIntoRequestData = (address: Address): SquirrelAddress => {
  return {
    address: address.formatted,
    addressUnit: address.unitNumber,
    addressNo: address.streetNumber,
    addressStreet: address.street,
    addressSuburb: address.suburb,
    addressCity: address.city,
    addressPostCode: address.postCode,
    addressCountry: address.country,
    addressMeshblock: address.meshblock,
  };
};
