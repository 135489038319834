import { Injectable } from '@angular/core';

import { Actions, createEffect } from '@ngrx/effects';

import { AuthService } from '@views/misc/auth/auth.service';

import * as actions from './profile.actions';
import { ProfileService } from './profile.service';

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private profileService: ProfileService,
    private authService: AuthService,
  ) {}

  fetchItem$ = createEffect(
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: () => this.profileService.fetchItem(),
      errorMessage: 'Sorry, there has been an error fetching your profile data',
      startAction: actions.profileFetchItemStart,
      successAction: actions.profileFetchItemSuccess,
      failAction: actions.profileFetchItemFail,
    }),
  );

  updateItem$ = createEffect(
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: action => this.profileService.updateItem(action.payload),
      successMessage: 'Your profile has been updated',
      errorMessage: 'Sorry, there has been an error updating your profile data',
      startAction: actions.profileUpdateItemStart,
      successAction: actions.profileUpdateItemSuccess,
      failAction: actions.profileUpdateItemFail,
    }),
  );
}
