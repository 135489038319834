import { ActionReducerMap, MetaReducer } from '@ngrx/store';

import * as auth from '@views/misc/auth/auth.redux';
import * as constants from '@views/misc/constants/constants.redux';
import * as enums from '@views/misc/enums/enums.redux';
import * as navigationRedux from '@views/misc/navigation/navigation.redux';
import * as user from '@views/misc/user/user.redux';
import * as notificationsRedux from '@views/notifications/notifications.redux';
import { NotificationsState } from '@views/notifications/notifications.redux';
import * as profileRedux from '@views/profile/profile.redux';
import { ProfileState } from '@views/profile/profile.redux';
import * as securityRedux from '@views/security/security.redux';
import { SecurityState } from '@views/security/security.redux';
import * as bankAccountsRedux from '@views/settings/bank-accounts/bank-accounts.redux';
import { BankAccountsState } from '@views/settings/bank-accounts/bank-accounts.redux';
import * as entitiesRedux from '@views/settings/entities/entities.redux';
import { EntitiesState } from '@views/settings/entities/entities.redux';
import * as filesRedux from '@views/settings/files/files.redux';
import { FilesState } from '@views/settings/files/files.redux';

import { ILegalCopyState } from '@components/legal-copy/legal-copy.reducers';
import * as legalCopyRedux from '@components/legal-copy/legal-copy.redux';
import { IInvestorNotificationsState } from '@components/notification-banners/notification-banners.reducers';
import * as investorNotificationsRedux from '@components/notification-banners/notification-banners.redux';

import * as formErrors from '@core/utilities/networkLayer/interceptor.redux';

export interface State {
  auth: auth.AuthState;
  apiError: formErrors.IFormErrorsState;
  enums: enums.EnumsState;
  constants: constants.ConstantsState;
  entities: EntitiesState;
  navigation: navigationRedux.NavigationState;
  profile: ProfileState;
  notifications: NotificationsState;
  security: SecurityState;
  bankAccounts: BankAccountsState;
  files: FilesState;
  legalCopy: ILegalCopyState;
  notificationBanners: IInvestorNotificationsState;
}

export const reducers: ActionReducerMap<State> = {
  auth: auth.reducer,
  apiError: formErrors.reducer,
  enums: enums.reducer,
  constants: constants.reducer,
  entities: entitiesRedux.reducer,
  navigation: navigationRedux.reducer,
  profile: profileRedux.reducer,
  notifications: notificationsRedux.reducer,
  security: securityRedux.reducer,
  bankAccounts: bankAccountsRedux.reducer,
  files: filesRedux.reducer,
  legalCopy: legalCopyRedux.reducer,
  notificationBanners: investorNotificationsRedux.reducer,
};

export const effects = [
  auth.Effects,
  enums.Effects,
  constants.Effects,
  user.Effects,
  entitiesRedux.Effects,
  profileRedux.Effects,
  notificationsRedux.Effects,
  securityRedux.Effects,
  bankAccountsRedux.Effects,
  filesRedux.Effects,
  legalCopyRedux.Effects,
  investorNotificationsRedux.Effects,
];

export const metaReducers: MetaReducer<State>[] = [];
