import { environment } from '@environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { forkJoin } from 'rxjs';

import { AuthService } from '@views/misc/auth/auth.service';
import { StorageService } from '@views/misc/auth/storage.service';

import { UpdateProfileRequest } from './profile.types';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private storageService: StorageService,
  ) {}

  fetchItem() {
    const userId = this.storageService.getUserId();
    const createRequest = target =>
      this.http.get(target, {
        headers: this.authService.createAuthHeaders(),
      });

    return forkJoin(
      createRequest(`${environment.endpointApiOne}/v1/users/${userId}`),
      createRequest(`${environment.endpointApiOne}/v1/users/can-close-update`),
    );
  }

  updateItem(payload: UpdateProfileRequest) {
    const userId = this.storageService.getUserId();

    return this.http.put(`${environment.endpointApiOne}/v1/users/${userId}/update-details`, payload, {
      headers: this.authService.createAuthHeaders(),
    });
  }
}
