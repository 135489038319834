import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

import { SnackbarComponent } from './snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  snackBarRef: MatSnackBarRef<SnackbarComponent>;

  constructor(private snackBar: MatSnackBar) {}

  public setError = (message: string, duration = 12000): void => {
    if (this.isMessageValid(message)) {
      this.snackBarRef = this.snackBar?.openFromComponent(SnackbarComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration,
        data: {
          icon: 'fa-triangle-exclamation',
          color: 'warn',
          message,
          buttonMessage: 'Dismiss',
        },
      });
    }
  };

  public addSuccess = (message: string, duration = 8000): void => {
    if (this.isMessageValid(message)) {
      this.snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration,
        data: {
          icon: 'fa-circle-check',
          color: 'success',
          message,
          buttonMessage: 'Dismiss',
        },
      });
    }
  };

  isMessageValid = (message: string): boolean => {
    // must be a defined message
    return message !== undefined && message.length > 0;
  };
}
