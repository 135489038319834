import { Component, Input } from '@angular/core';

import { createUniqueId } from '@core/utilities/unique-id';

/** An `<svg />` version of the standard _Squirrel_ t logo. */
@Component({
  selector: 'app-squirrel-logo',
  standalone: true,

  template: `<svg
    class="squirrel-logo"
    [class.inverted]="isInverted"
    [attr.aria-describedby]="titleId + ' ' + describeId"
    viewBox="0 0 196 60"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title [attr.id]="titleId">Squirrel</title>
    <desc [attr.id]="describeId">Squirrel Logo</desc>
    <path
      d="M0,29.6L9.2,27c0.9,3.6,3.8,6.4,8.8,6.5s6.3-1.6,6.6-3.5-0.7-2.8-3.5-3.7L11.8,23c-4.8-1.6-8-5.2-7-10.3S12.7,1.4,21,1.5,35.1,6.4,36.2,11.8l-8.9,2.4c-1-2.7-4-4.7-7.7-4.7s-5.4,1.4-5.7,3.1,0.5,2.3,2.1,2.8l9.1,3.2c5.5,1.9,9.8,4.4,8.7,10.9s-8.4,12.1-17.9,12S1.2,35.5,0,29.6"
      transform="translate(0 -1.4)"
    />
    <path
      d="M98.7,13L94.1,40.7H85.5l0.5-2.9a12.6,12.6,0,0,1-8.8,3.6c-7.4,0-10-5-8.8-12.3L71,13h8.7L77.3,27.4c-0.6,3.6.5,6,4,6s5.8-2.7,6.5-6.9L90,12.9h8.7Z"
      transform="translate(0 -1.4)"
    />
    <polygon points="101.6 11.6 110.3 11.6 105.8 39.3 97.1 39.3 101.6 11.6" />
    <path
      d="M102.8,5.9a5.5,5.5,0,0,1,5.3-4.4A3.8,3.8,0,0,1,112,5.9a5.6,5.6,0,0,1-5.3,4.5,3.8,3.8,0,0,1-3.9-4.5"
      transform="translate(0 -1.4)"
    />
    <path
      d="M130.8,12.6l-1.3,8.1c-5.7-1-8.9,1.5-10.1,8.4l-1.9,11.6h-8.7L113.3,13H122l-0.6,3.8a9.5,9.5,0,0,1,9.4-4.1"
      transform="translate(0 -1.4)"
    />
    <path
      d="M150.6,12.6l-1.3,8.1c-5.7-1-8.9,1.5-10.1,8.4l-1.9,11.6h-8.7L133.1,13h8.7l-0.6,3.8a9.5,9.5,0,0,1,9.4-4.1"
      transform="translate(0 -1.4)"
    />
    <path
      d="M148.9,26.9a17.2,17.2,0,0,1,17-14.7c9.2,0,13.5,6.4,12.2,14.3a18.4,18.4,0,0,1-.7,2.9H157c0.1,2.6,1.7,4.8,6.1,4.8a6.8,6.8,0,0,0,6-2.8l7.5,2.3c-2,3.4-7.4,7.8-14.8,7.8-9,0-14.2-5.9-12.9-14.6m21.1-2.7c0.1-2.5-1.7-4.7-5.3-4.7a7.2,7.2,0,0,0-6.9,4.7h12.2Z"
      transform="translate(0 -1.4)"
    />
    <polygon points="184.7 0 193.4 0 187 39.3 178.3 39.3 184.7 0" />
    <path
      d="M61.2,59c-9.3,0-7.9-7.7-7.9-7.9s0.1,2.5,3.9,2.7,7.5-2.1,10.6-3.8c4.9-2.7,7.9-3.7,11-3.7,9.3,0,9.3,9.8,9.3,9.8s-3.9-5.5-14.2-.7C68.7,57.7,65.1,59,61.2,59"
      transform="translate(0 -1.4)"
    />
    <path
      d="M53.3,51.1a6.9,6.9,0,0,0,8.9-1.4l6-36.7H59.5l-0.4,2.5c-1.6-2.1-4-3.3-7.4-3.3-7.5,0-14.5,6.2-15.9,14.6s3.6,14.6,11.1,14.6a12.5,12.5,0,0,0,8.5-3.3ZM49.7,33.8c-3.7,0-6-2.8-5.3-7A7.7,7.7,0,0,1,52,19.9c3.8,0,6.2,2.7,5.5,6.8a8,8,0,0,1-7.7,7"
      transform="translate(0 -1.4)"
    />
  </svg> `,
  styleUrls: ['./squirrel.component.scss'],
})
export class SquirrelLogoComponent {
  /**
   * "Inverts" the `color` when on a Dark background. The default design is setup
   * for a Light theme.
   */
  @Input() isInverted?: boolean;

  /** Unique ID that identifies the `<title />` tag to users. */
  public titleId: string;

  /** Unique ID that identifies the `<desc />` tag to users. */
  public describeId: string;

  constructor() {
    this.createUniqueIds();
  }

  /**
   * Creates unique ID's that link the logo design and the meta data together
   * from an accessibility perspective.
   */
  createUniqueIds = () => {
    const uniqueId = createUniqueId();

    this.titleId = `${uniqueId}_title`;
    this.describeId = `${uniqueId}_described`;
  };
}
